<template>
  <div
    v-if="shouldShow"
    :class="{
      'goods-review__filter-panel': true,
      'limit-width': isRomwe
    }"
  >
    <div
      v-if="!hiddenTitle"
      class="panel-box"
      @click="onTitleClick"
    >
      <span>{{ titleText }}</span>
      <i
        :class="{
          suiiconfont: true,
          sui_icon_more_down_16px: true,
          reverse: panelShow
        }"
      ></i>
    </div>
    <S-slide
      ref="slidePanel"
      :visible.sync="panelShow"
      :modal="true"
      @closed="handleClose"
    >
      <div
        class="goods-review__dropdown filter-panel"
        :class="{ 'romwe-style': isRomwe }"
      >
        <div
          class="scroll-box"
          :style="{ maxHeight: selectAreaMaxHeight }"
        >
          <!-- Colors -->
          <template v-if="hasColorOption">
            <p>{{ language.SHEIN_KEY_PWA_16265 }}</p>
            <ul>
              <li
                v-for="(color, index) in colors"
                :key="index"
                class=""
                :class="{
                  option: true,
                  'color-option': true,
                  active: color.goods_id === selectedColor,
                  loading: loading
                }"
                @click="() => onSelectColor(color)"
              >
                <img :src="transformImg({ img: color.goods_color_image })" />
                <span>{{ color.color_text }}</span>
              </li>
            </ul>
          </template>

          <!-- Size -->
          <template v-if="hasSizeOption">
            <p>{{ sizeInfo.attr_name }}</p>
            <ul>
              <li
                v-for="(size, index) in sizeInfo.attr_values"
                :key="index"
                :class="{
                  option: true,
                  'size-option': true,
                  active: +size.attr_value_id === +selectedSize,
                  loading: loading
                }"
                @click="() => onSelectSize(size, index)"
              >
                <span>{{ size.attr_value }}{{ getSizeCorrespond(size.attr_value_en) }} </span>
              </li>
            </ul>
          </template>
        </div>

        <!-- 底部栏 -->
        <div class="filter-panel_bottom-bar">
          <div class="review-count-box">
          </div>
          <div class="operate-box">
            <i
              class="iconfont icon-delete"
              @click.stop="resetFilter"
            ></i>
            <s-button
              :type="['primary']"
              :class="{ 'mshe-btn-black': true, 'with-loading': loading }"
              @click="onClosePanel"
            >
              {{ language.SHEIN_KEY_PWA_15042 }}
              <s-loading :show="loading" />
            </s-button>
          </div>
        </div>
      </div>
    </S-slide>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { transformImg } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { Button as SButton } from '@shein/sui-mobile'

export default {
  name: 'ReviewFilterPanel',
  comments: {
    SButton
  },
  props: {
    hiddenTitle: {
      type: Boolean,
      default: false
    },
    reviewPanelShow: {
      type: Boolean,
      default: true
    },
    commentList: {
      type: Array,
      default: () => []
    },
    colors: {
      type: Array,
      default: () => []
    },
    sizeInfo: {
      type: Object,
      default: () => ({})
    },
    sizeCorrespondMap: {
      type: Object,
      default: () => ({})
    },
    selectedSize: {
      type: [String, Number],
      default: ''
    },
    selectedColor: {
      type: String,
      default: ''
    },
    displayCommentSum: {
      type: [String, Number],
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      panelShow: false,
      // 面板被动关闭标志
      passiveCloseFlag: false,
      winHeight: 800
    }
  },
  computed: {
    ...mapState('newProductDetail/CommentPopup', ['showSortList', 'showColorSizeList']),
    ...mapGetters('newProductDetail/common', ['language', 'pageCommonInfo']),
    isRomwe() {
      const { WEB_CLIENT } = this.pageCommonInfo
      return WEB_CLIENT === 'romwe'
    },
    // 块是否需要展示
    shouldShow() {
      const { hasColorOption, hasSizeOption } = this
      return hasColorOption || hasSizeOption
    },
    // 尺码和颜色选择区最大高度
    selectAreaMaxHeight() {
      const { panelShow, winHeight } = this
      // 这种写法是为了保证其值会更新
      const restHeight = winHeight - 300
      if (panelShow) {
        return `${restHeight * 0.6}px`
      }
      return `${restHeight * 0.6}px`
    },
    // 标题栏文本
    titleText() {
      const { colors, selectedColor, language, getSelectedSize, getSelectedSizeText } = this
      const selectedSize = getSelectedSize()

      if (!selectedColor && !selectedSize) {
        return language.SHEIN_KEY_PWA_21445
      }

      let text = ''
      if (selectedColor) {
        const colorCell = colors.find(color => color.goods_id === selectedColor)
        if (colorCell) {
          text = colorCell.color_text || ''
        }
      }
      if (selectedSize) {
        const sizeText = getSelectedSizeText()
        text = text ? `${text}, ${sizeText}` : sizeText
      }
      if (text) {
        return `${language.SHEIN_KEY_PWA_21446}: "${text}"`
      }

      return language.SHEIN_KEY_PWA_21445
    },
    // 是否存在颜色选项
    hasColorOption() {
      const { colors } = this
      return colors && colors.length > 1
    },
    // 是否存在尺码选项
    hasSizeOption() {
      const { sizeInfo = {} } = this
      const { attr_values } = sizeInfo
      return attr_values && attr_values.length
    }
  },
  watch: {
    showColorSizeList(val) {
      this.panelShow = val
      
    },
    commentList() {
      this.$forceUpdate()
      this.$nextTick(function () {
        this.$refs.slidePanel?.calWithModal()
      })
    },
    shouldShow(val) {
      if (val === true) {
        // 埋点上报
        daEventCenter.triggerNotice({
          daId: '1-6-2-44'
        })
      }
    },
    panelShow: {
      handler(val, oldVal) {
        val && daEventCenter.triggerNotice({
          daId: '1-6-2-75',
        })
        const { passiveCloseFlag } = this
        // 关闭时，上报当前选择数据
        const { selectedColor, getSelectedSizeText } = this
        if (!passiveCloseFlag && oldVal === true && val === false) {
         
          daEventCenter.triggerNotice({
            daId: '1-6-2-49',
            extraData: {
              color_id: `${selectedColor}`,
              size_type: getSelectedSizeText()
            }
          })
        }
        if (val === false) {
          daEventCenter.triggerNotice({
            daId: '1-6-2-76',
            extraData: {
              type: `${selectedColor}${selectedColor && getSelectedSizeText() ? '‘' : ''}${getSelectedSizeText()}`,
            }
          })
        }
        // 打开时重置被动关闭标志
        if (val === true) {
          this.passiveCloseFlag = false
        }

        // 向上级冒泡
        this.$emit('panelSwitch', val)
      }
    },
    reviewPanelShow: {
      handler(val) {
        if (val === false) {
          this.passiveCloseFlag = true
          this.onClosePanel()
        }
      }
    },
    showSortList(newVal) {
      if (newVal) {
        this.onClosePanel()
      }
    }
  },
  mounted() {
    const { shouldShow } = this
    if (shouldShow === true) {
      // 埋点上报
      daEventCenter.triggerNotice({
        daId: '1-6-2-44'
      })
    }
  },
  updated() {
    this.winHeight = window.innerHeight
  },
  methods: {
    ...mapMutations('newProductDetail/CommentPopup', ['updateShowSortList', 'updateShowColorSizeList']),
    transformImg,
    // 面板标题点击
    onTitleClick() {
      // 首先关闭另一个面板
      this.updateShowSortList(false)
      this.$nextTick(() => {
        this.updateShowColorSizeList(!this.showColorSizeList)
        // 打开面板时上报埋点
        if (!this.showColorSizeList) {
          daEventCenter.triggerNotice({
            daId: '1-6-2-45'
          })
        }
      })
    },
    handleClose() {
      this.updateShowColorSizeList(false)
    },
    // 获取本地尺码字符
    getSizeCorrespond(sizeValue) {
      const { sizeCorrespondMap } = this
      if (!sizeCorrespondMap || sizeValue === 'one-size') {
        return ''
      }
      const correspond = sizeCorrespondMap[sizeValue]
      return correspond ? `(${correspond})` : ''
    },
    // 颜色项点选
    onSelectColor(color) {
      this.$emit('selectColor', color)
      // 埋点上报
      // const { selectedColor } = this
      // if (color.goods_id !== selectedColor) {
      //   daEventCenter.triggerNotice({
      //     daId: '1-6-2-46',
      //     extraData: {
      //       color_id: `${color.goods_id}`
      //     }
      //   })
      // }
    },
    // 尺码项点选
    onSelectSize(size, index) {
      // const { active } = size
      this.$emit('selectSize', size, index)
      // 埋点上报
      // if (!active) {
      //   const { getSizeCorrespond } = this
      //   const sizeKey = size.attr_value
      //   daEventCenter.triggerNotice({
      //     daId: '1-6-2-47',
      //     extraData: {
      //       size_type: `${sizeKey}${getSizeCorrespond(sizeKey)}`
      //     }
      //   })
      // }
    },
    // 获取当前选中的尺码
    // ! 因上游数据结构设计缺陷，暂时无法用computed属性代替
    getSelectedSize() {
      const { sizeInfo = {} } = this
      const { attr_values = [] } = sizeInfo
      const selectedSize = attr_values.find(size => +size.attr_value_id === +this.selectedSize)
      return selectedSize || null
    },
    // 获取当前选中尺码的字符串表达
    // ! 因上游数据结构设计缺陷，暂时无法用computed属性代替
    getSelectedSizeText() {
      const { getSelectedSize, sizeCorrespondMap } = this
      const selectedSize = getSelectedSize()
      if (!selectedSize) {
        return ''
      }
      const sizeText = selectedSize.attr_value_en
      if (sizeCorrespondMap) {
        const correspond = sizeCorrespondMap[sizeText]
        if (correspond) {
          return `${sizeText}(${correspond})`
        }
      }
      return sizeText
    },
    // 重置筛选
    resetFilter() {
      const { selectedColor, getSelectedSize } = this
      if (selectedColor) {
        this.$emit('resetFilter')
      }
      const selectedSize = getSelectedSize()
      if (selectedSize) {
        this.$emit('resetFilter')
      }

      // 埋点上报
      if (selectedColor || selectedSize) {
        daEventCenter.triggerNotice({
          daId: '1-6-2-48'
        })
      }
    },
    // 关闭面板
    onClosePanel() {
      this.updateShowColorSizeList(false)
    }
  }
}
</script>

<style lang="less">
/* stylelint-disable  selector-max-specificity */
.comment-filterslected() {
  position: absolute;
  top: 0;
  right: 0;
  .margin-r(-1px);
  content: '';
  width: 0.4rem;
  height: 0.4rem;
  display: inline-block;
  background: url('/pwa_dist/images/filter-selected-457931bc64.png');
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(0deg) /*rtl:rotate(-90deg)*/;
}

.goods-review__filter-panel {
  .panel-box {
    white-space: nowrap;
    height: 1.1734rem;
    width: 100%;
    font-size: 0.374rem;
    line-height: 1.1734rem;
    padding: 0 0.32rem;
    color: @sui_color_gray_dark1;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    [class*='iconfont'] {
      // .margin-l(0.2rem);
      color: #999;
    }

    span {
      display: inline-block;
      margin-right: 0.106667rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      height: 100%;
    }
  }

  .sui_icon_more_down_16px.reverse {
    transform: rotate(180deg);
    transition: transform 0.3s;
  }

  &.limit-width {
    max-width: 64%;
  }
}

.goods-review__dropdown.filter-panel {
  padding: 0.426667rem 0.32rem;

  .scroll-box {
    overflow: scroll;
  }

  p {
    font-size: 0.32rem;
    line-height: 0.373333rem;
    color: @sui_color_gray_light1;
    margin-bottom: 0.213333rem;
  }

  .option {
    display: inline-block;
    padding: 0 0.21rem;
    line-height: 0.78rem;
    border: 1px solid #e5e5e5;
    margin-bottom: 0.32rem;
    text-align: center;
    .margin-r(0.32rem);
    color: #666;
    span {
      .font-dpr(24px);
      vertical-align: middle;
    }

    &.active {
      position: relative;
      color: #222 /*rw:#ff696e*/;
      border: 1px solid #222 /*rw:1px solid #ff696e*/;
      &::before {
        .comment-filterslected;
        /*rw:begin*/
        display: none;
      }
    }
    &.loading {
      pointer-events: none;
    }
  }

  .option.color-option {
    img {
      width: 0.45rem;
      height: 0.45rem;
      .margin-r(0.08rem);
      vertical-align: middle;
      border: 1px solid rgba(34, 34, 34, 0.06);
    }
  }

  .option.size-option {
    padding: 0 0.373333rem;
  }
}

.goods-review__dropdown.romwe-style {
  .option {
    border-radius: 0.506667rem;
    min-width: 1.28rem;
  }
}

.filter-panel_bottom-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;

  .review-count-box > p:first-child {
    color: #000;
    font-size: 0.373333rem;
    font-weight: 700;
    line-height: 0.453333rem;
  }
  .review-count-box > p:last-child {
    color: #000;
    .font-dpr(22px);
    font-weight: 400;
    line-height: 0.346667rem;
    margin-bottom: 0;
  }

  .operate-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon-delete {
      font-size: 0.533333rem;
      margin-right: 0.64rem;
    }

    button {
      padding: 0 0.32rem;
      height: 0.853333rem;
      line-height: 0.853333rem;
      font-size: 0.373333rem;
    }
  }
}
.S-button {
  &.with-loading {
    position: relative;
    color: transparent;
    .S-loading {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
    }
    .S-loading__common-inner {
      margin: 0 auto;
      width: 0.427rem;
      height: 0.427rem;
    }
    .S-loading__circular-path-under {
      stroke: rgba(255, 255, 255, 0.3);
    }
    .S-loading__circular-path {
      stroke: rgba(255, 255, 255, 1);
    }
  }
}
</style>
