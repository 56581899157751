<template>
  <S-slide
    ref="slidePanel"
    :visible.sync="panelShow"
    :modal="true"
    @closed="onClosePanel"
  >
    <div class="goods-review__dropdown filter-panel review-star__panel">
      <div
        v-for="(item, index) in rating"
        :key="index"
      >
        <div class="list-filter__con-item">
          <span
            class="filter-rating-span"
            :class="{ active: selectedRating.includes(item), loading: loading }"
            @click="$emit('chooseRating',item,true)"
          >
            {{ item }}
            <i class="suiiconfont sui_icon_star_5_12px_2"></i>
          </span>
        </div>
      </div>

      <!-- 底部栏 -->
      <div class="filter-panel_bottom-bar">
        <div class="review-count-box"></div>
        <div class="operate-box">
          <i
            class="iconfont icon-delete"
            @click.stop="resetFilter"
          ></i>
          <s-button
            :type="['primary']"
            :class="{ 'mshe-btn-black': true, 'with-loading': loading }"
            @click="onClosePanel"
          >
            {{ language.SHEIN_KEY_PWA_15042 }}
            <s-loading :show="loading" />
          </s-button>
        </div>
      </div>
    </div>
  </S-slide>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { transformImg } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { Button as SButton } from '@shein/sui-mobile'

export default {
  name: 'StarFilterPanel',
  comments: {
    SButton
  },
  props: {
    rating: {
      type: Array,  
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    selectedRating: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    },
    commentList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      panelShow: false,
      // 面板被动关闭标志
      passiveCloseFlag: false
    }
  },
  computed: {
    ...mapState('newProductDetail/CommentPopup', ['showRatingList']),
    ...mapGetters('newProductDetail/common', ['language', 'pageCommonInfo'])
  },
  watch: {
    commentList() {
      this.$forceUpdate()
      this.$nextTick(function () {
        this.$refs.slidePanel?.calWithModal()
      })
    },
    showRatingList(val){
      this.panelShow = val
    },
    panelShow(val) {
      !val && daEventCenter.triggerNotice({
        daId: '1-6-2-17',
        extraData: {
          type: this.selectedRating.replace(/,/g, '\''),
          review_num: this.reviewNum
        }
      })
    },
    shouldShow(val) {
      if (val === true) {
        // 埋点上报
        daEventCenter.triggerNotice({
          daId: '1-6-2-44'
        })
      }
    },
  },
  methods: {
    ...mapMutations('newProductDetail/CommentPopup', ['updateShowRatingList']),
    calcHeight() {
      this.$forceUpdate()
      this.$nextTick(function () {
        this.$refs.slidePanel?.calWithModal()
      })
    },
    transformImg,
    // 重置筛选
    resetFilter() {
      this.$emit('handleDelete', true)
    },
    // 关闭面板
    onClosePanel() {

      this.updateShowRatingList(false)
    }
  }
}
</script>

<style lang="less">
.review-star__panel{
  position: sticky;
  z-index: 1000;
  top: 0;
  
  .list-filter__con-item{
      height: 0.7467rem;
      width: 1.4933rem;
      margin-right: 0.3733rem;
      margin-bottom: 0.64rem;
  }
  .list-filter__con-item>span{
      height: 0.7467rem;
      line-height: 0.7467rem;
      
  }
}
.S-button {
  &.with-loading {
    position: relative;
    color: transparent;
    .S-loading {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
    }
    .S-loading__common-inner {
      margin: 0 auto;
      width: 0.427rem;
      height: 0.427rem;
    }
    .S-loading__circular-path-under {
      stroke: rgba(255, 255, 255, 0.3);
    }
    .S-loading__circular-path {
      stroke: rgba(255, 255, 255, 1);
    }
  }
}
</style>
