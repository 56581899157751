<template>
  <div
    class="goods-review__sortfilt"
    :class="showSortList ? 'active' : ''"
  >
    <div
      class="goods-review__sortfilt-items"
      @click="toggleSort"
    >
      <span>
        <span class="goods-review__sortfilt-sort">{{ sortName }}</span>
        <i
          :class="{
            suiiconfont: true,
            sui_icon_more_down_16px: true,
            reverse: showSortList
          }"
        ></i>
      </span>
    </div>
    <div
      class="goods-review__sortfilt-items j-push-history-hash"
      state="comment-filter"
      @click="toggleFilter"
    >
      <span class="filter">
        <em :class="{ hasbadge: getFilterBadge }">{{ filterName }}</em>
        <i class="suiiconfont sui_icon_filter_16px"></i>
        <span
          v-if="getFilterBadge"
          class="badge"
        >
          {{ getFilterBadge }}
        </span>
      </span>
    </div>
    <S-slide
      ref="s-slide"
      :visible.sync="showSortListProxy"
      :modal="true"
    >
      <div class="goods-review__dropdown">
        <slot name="sortItems"></slot>
      </div>
    </S-slide>
    <!-- <div
      v-if="showDesc"
      class="sort-list-mask"
      @click="toggleSort"
    ></div> -->
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'SortFilter',
  props: {
    sortName: {
      type: String,
      default: ''
    },
    filterName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showSortListProxy: false
    }
  },
  computed: {
    ...mapState('newProductDetail/CommentPopup', [
      'showSortList',
      'withPicture',
      'comment_rank',
      'onlyShowReport'
    ]),
    getFilterBadge() {
      let result = 0

      if (this.withPicture) {
        result += 1
      }

      if (this.comment_rank) {
        result += 1
      }

      if (this.onlyShowReport) {
        result += 1
      }

      return result
    }
  },
  watch: {
    showSortListProxy(newVal) {
      newVal && daEventCenter.triggerNotice({
        daId: '1-6-2-79'
      })
      this.updateShowSortList(newVal)
    },
    showSortList(newVal) {
      this.showSortListProxy = newVal
      if (newVal) {
        $('.j-review-scroll-ctn').css('overflow', 'hidden')
        $('body').css('overflow', 'hidden')
      } else {
        $('.j-review-scroll-ctn').css('overflow', '')
        $('body').css('overflow', '')
      }
      this.$nextTick(() => {
        this.$refs['s-slide'].calHeight()
      })
    }
  },
  methods: {
    ...mapMutations('newProductDetail/CommentPopup', ['updateShowSortList']),
    toggleSort: function () {
      this.updateShowSortList(!this.showSortList)
      this.$parent.current = this
      this.$emit('toggle')
      if (this.showSortList) {
        sa('send', { activity_name: 'click_sort' })
      }
    },
    toggleFilter: function () {
      this.$emit('changeFilter', true)
      if (typeof sa !== 'undefined') {
        sa('send', { activity_name: 'click_filter' })
      }
      this.updateShowSortList(false)
    }
    // enterAnimation (el) {
    //   el.className += ' S-animation__drawer_active'
    // },
    // leaveAnimation (el) {
    //   el.className = el.className.replace('S-animation__drawer_active', '')
    // }
  }
}
</script>

<style lang="less">
.goods-review {
  &__sortfilt {
    white-space: nowrap;
    height: 1.1734rem;
    width: 100%;
    font-size: 0.374rem;
    position: absolute;
    left: 0;
    z-index: @zindex-attrmenusize;
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
    box-shadow: 0 0.15rem 0.15rem 0.1rem rgba(0, 0, 0, 0.03);
    [class*='iconfont'] {
      // .margin-l(0.2rem);
      color: #999;
    }
    /* stylelint-disable selector-class-pattern */
    .icon-Product_list_down {
      /* stylelint-disable-line */
      display: inline-block;
      .font-dpr(32px);
      font-weight: normal;
    }
  }
  &__sortfilt-items {
    width: 5rem;
    text-align: center;
    color: #666;
    padding: 0.213333rem;
    background: #fff;
    display: inline-block;
    &:first-child {
      color: #222;
    }
    .filter {
      position: relative;
    }
    .hasbadge {
      font-weight: bold;
      color: #222;
    }
    .badge {
      position: absolute;
      padding: 0.013333rem;
      top: -0.1rem;
      .right(-0.25rem);
      .font-dpr(27px);
      width: 0.373333rem;
      height: 0.373333rem;
      background: #ccc;
      border-radius: 100%;
      text-align: center;
      line-height: 0.3467rem;
      color: #fff;
    }

    .sui_icon_more_down_16px {
      display: inline-block;
    }
    .sui_icon_more_down_16px.reverse {
      transform: rotate(180deg);
      transition: transform 0.3s;
    }
  }
  &__sortfilt-sort{
    font-weight: bold;
  }
  &__dropdown {
    background: #fff;
  }
}
</style>
