<template>
  <span
    class="c-switch"
    :class="{ 'c-switch-checked': value }"
    @click="handleClick()"
  > 
    <span class="c-switch-inner"></span> 
  </span>
</template>

<script>
export default {
  name: 'SSwitch',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    change: {
      type: Function,
      default: () => {}
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick: function () {
      if (this.disable) return
      this.$emit('input', !this.value)
      this.$emit('change', !this.value)
    }
  }
}
</script>
