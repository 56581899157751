<template>
  <div @click.stop.prevent>
    <!-- reviews　信息弹框 -->
    <s-drawer
      :visible.sync="isShow"
      direction="rtl"
      type="full"
      :no-header="true"
      class="goods-detail__comment-drawer"
      :close-on-click-modal="false"
      :show-close-animation="showCloseAnimation"
      @open="handleOpen()"
    >
      <div
        class="review-modal"
        :class="{ 'review-modal__top': showNewReviewFilter }"
        :style="{ 'overflow-y': viewComment || drawerFilterShow ? 'hidden' : '' }"
      >
        <section class="mshe-header-fixed">
          <s-title-nav class="j-sui-header">
            <template slot="preblock">
              <s-title-nav-item
                class="j-icon-back"
                icon-class="suiiconfont sui_icon_nav_back_24px"
                tabindex="0"
                :role="language.SHEIN_KEY_PWA_17952"
                :aria-label="language.SHEIN_KEY_PWA_15234"
                @keyup.enter.stop.prevent="goBack"
                @click="goBack"
              />
            </template>
            <template slot="default">
              <h1
                class="goods-review__title"
                tabindex="0"
                :aria-label="language.SHEIN_KEY_PWA_15008"
                :role="language.SHEIN_KEY_PWA_15202"
              >
                {{ language.SHEIN_KEY_PWA_15008 }}
              </h1>
            </template>
            <template slot="endblock">
              <a
                :href="`${langPath}/cart`"
                class="bag-btn-wrap animated header-bag j-header-bag-is-review"
                :data-cart-num="cartSumQuantity"
                @click="bagClickEvt"
              >
                <i class="suiiconfont sui_icon_nav_shoppingbag_24px"></i>
                <em
                  class="j-header-cart-num"
                  :class="{ 'mshe-hide': !+cartSumQuantity }"
                >
                  {{ cartSumQuantity }}
                </em>
              </a>
            </template>
          </s-title-nav>
        </section>
        <div class="j-review-scroll-ctn mshe-trans-contain-footer view-review view-review-mt">
          <!-- 评论真实性说明文案 -->
          <div
            v-if="isShowValuableReviews"
            v-expose="{id: '1-6-2-88', code: 'reset-comment-events'}"
            class="goods-review__valuable-reviews"
          >
            <Icon
              name="sui_icon_certification_16px"
              size="16px"
              color="#198055"
            />
            <span class="goods-review__valuable-tips">{{ language.SHEIN_KEY_PWA_31069 || 'SHEIN encourages genuine and valuable reviews.' }}</span>
          </div>
          <!-- 评论评分 -->
          <div
            v-if="showRatePanel && !onlyShowSameItem"
            class="goods-review__rate-wrap"
            :class="{ 'more-padding-bottom': showLocalReviews && !showNewReviewcard }"
          >
            <RatePanel
              class="goods-review__rate-nomg"
              :has-true-to-size="hasCommentsToSize"
              @getIsShowRank="getIsShowRank"
            >
              <ShowLocalSwitch
                v-if="showLocalReviews && !onlyOutStoreReview && showNewReviewcard"
                ref="ShowLocalSwitchRef"
                class="show-local__container-new"
                :loading="commentLoading"
                @change="handleShowLocalChange"
              />
            </RatePanel>
            <div
              v-if="showNewReviewcard"
              class="review-split"
            ></div>
          </div>

          <template v-if="showLocalReviews && !onlyOutStoreReview && !showNewReviewcard">
            <!-- 只展示本地评论 -->
            <ShowLocalSwitch
              ref="ShowLocalSwitchRef"
              :loading="commentLoading"
              @change="handleShowLocalChange"
            />
            <div class="review-split"></div>
          </template>

          <!-- Reviews by product -->
          <div v-if="!showNewReviewFilter && !onlyShowSameItem">
            <div
              v-if="!onlyOutStoreReview"
              :key="'review-bt-product'"
              :class="{
                'goods-review__filter-wrap': true,
                'flex-box': isRomwe
              }"
            >
              <!-- :display-comment-sum="displayCommentSum" -->
              <ReviewFilterPanel
                :review-panel-show="viewComment"
                :comment-list="allList"
                :colors="productColor"
                :selected-color="commentFilterGoodsId"
                :size-info="filterAttrs[0]"
                :size-correspond-map="sizeCorrespondMap"
                :selected-size="commentFilterAttrSizeId"
                :display-comment-sum="displaySum"
                :loading="commentLoading"
                @selectColor="
                  color => clickFilterColor(color.color_id, color.color_text, color.goods_id, color.goods_sn)
                "
                @selectSize="clickFilterSize"
                @resetFilter="resetColorSize"
                @panelSwitch="setPopPageFixed"
              />

              <div v-if="isRomwe"></div>

              <SortRomweV2
                v-if="isRomwe"
                ref="sortRomwe"
                aria-hidden="true"
              >
                <template slot="sortItems">
                  <ul class="view-review__sort-list">
                    <SortDownList
                      v-for="item in sortListMap"
                      :key="item.value"
                      :name="item.key"
                      :val="item.value"
                      :sort="item.sort"
                      :target="sort"
                      :data-value="item.keyEn"
                      @click="changeSort(item)"
                    />
                  </ul>
                </template>
              </SortRomweV2>
            </div>
            <PictureRatingFilterRomwe
              v-if="isRomwe && !onlyOutStoreReview"
              :pic-options="reviewsCon"
              :rating-options="rating"
              :selected-picture="withPicture"
              :selected-rating="comment_rank"
              @selectPicture="choosePicture"
              @selectRating="chooseRating"
            />

            <!--sort and filter-->
            <SortFilter
              v-if="!isRomwe && !onlyOutStoreReview"
              ref="sortFilter"
              :sort-name="language.SHEIN_KEY_PWA_15500"
              :filter-name="language.SHEIN_KEY_PWA_15044"
              aria-hidden="true"
              @changeFilter="changeFilter"
            >
              <template slot="sortItems">
                <ul class="view-review__sort-list">
                  <SortDownList
                    v-for="item in sortListMap"
                    :key="item.value"
                    :name="item.key"
                    :val="item.value"
                    :sort="item.sort"
                    :target="sort"
                    :data-value="item.keyEn"
                    @click="changeSort(item)"
                  />
                </ul>
              </template>
            </SortFilter>
          </div>
          <div 
            v-if="showNewReviewFilter && !onlyOutStoreReview && !onlyShowSameItem"
            class="review-new__filter"
          > 
            <NewSortFilter
              ref="newSortFilter"
              :language="language"
              :showReportList="reportList.length > 0"
              :active="{
                colorSize: commentFilterGoodsId||commentFilterAttrSizeId,
                rating: comment_rank,
                trialReport: onlyShowReport,
                picture: withPicture == reviewsCon?.[0]?.value,
              }"
              :colors="productColor"
              :colorSizeNumber="colorSizeNumber"
              :commentRank="comment_rank"
              :size-info="filterAttrs[0]"
              @chooseTrialReport="chooseTrialReport"
              @handlePicture="choosePicture(reviewsCon?.[0])"
            />
            <StarFilterPanel 
              ref="StarFilterPanelRef"
              :rating="rating"
              :selected-rating="comment_rank"
              :loading="commentLoading"
              :comment-list="allList"
              @chooseRating="chooseRating"
              @handleDelete="handleDelete"
            />
            <ReviewFilterPanel
              ref="ReviewFilterPanelRef"
              :hiddenTitle="true"
              :review-panel-show="viewComment"
              :comment-list="allList"
              :colors="productColor"
              :selected-color="commentFilterGoodsId"
              :size-info="filterAttrs[0]"
              :size-correspond-map="sizeCorrespondMap"
              :selected-size="commentFilterAttrSizeId"
              :display-comment-sum="displaySum"
              :loading="commentLoading"
              @selectColor="
                color => clickFilterColor(color.color_id, color.color_text, color.goods_id, color.goods_sn)
              "
              @selectSize="clickFilterSize"
              @resetFilter="resetColorSize"
              @panelSwitch="setPopPageFixed"
            />
          </div>
          <ReviewLabel
            v-if="showReviewLabel && !onlyShowSameItem"
            ref="reviewLabel"
            :reviewTagLine="reviewTagLine"
            style-type="list"
            :tag-list="reviewsTags"
            :active-id="tag_id"
            :loading="commentLoading"
            @reviewTagIdChange="handleReviewTagClick"
          />
          <SortRecommed 
            v-if="showNewReviewFilter && !onlyOutStoreReview && !onlyShowSameItem" 
            :language="language"
            @changeSort="changeSort"
          />
          <s-loading 
            :show="commentLoading" 
            :style="{ marginTop: showReviewLabel ? '25px' : '50px'}"
          />
          <div
            v-if="reportList.length == 0 && allList.length == 0 && !onlyOutStoreReview"
            class="view-review-null"
          >
            <div class="page-inner">
              <i class="iconfont icon-empty-history"></i>
              <p>{{ language.SHEIN_KEY_PWA_15819 }}</p>
            </div>
          </div>

          <!-- 仅存在站外评论提示 -->
          <div
            v-if="onlyOutStoreReview"
            class="view-review__onlyStoreTipInside"
          >
            <h2 class="view-review__only-store-tipContent">
              <span>{{ language.SHEIN_KEY_PWA_24442 }}</span>
              <i
                class="iconfont icon-_px product-intro__question"
                @click.stop="storeCommentTipShow = true"
              ></i>
            </h2>
            <SDialog
              :visible.sync="storeCommentTipShow"
              :append-to-body="true"
            >
              <div class="view-review__only-store-tipDialog">
                {{ language.SHEIN_KEY_PWA_24444 }}
              </div>
              <template #footer>
                <div class="view-review__only-store-tipDialogBtnBox">
                  <s-button
                    :type="['primary', 'H72PX']"
                    @click.stop="storeCommentTipShow = false"
                  >
                    {{ language.SHEIN_KEY_PWA_15146 }}
                  </s-button>
                </div>
              </template>
            </SDialog>
          </div>
          <!-- 评论列表 -->
          <div
            v-infinite-scroll="fetchMoreReviews"
            infinite-scroll-disabled="commentScrollLoading"
            infinite-scroll-nodata="noMoreComment"
            infinite-scroll-distance="300"
            class="view-review__list j-expose__page_reviews__list"
            :class="{
              'view-review__list-low':
                !isRomwe && isBatchTranslate && !(showReportTab && commentInfoData.length > 0) && !showReviewLabel,
              'with-padding-top': !isRomwe && !onlyOutStoreReview && !showReviewLabel && !commentLoading && !onlyShowSameItem
            }"
          >
            <!-- 进行排序时，试用报告单独排在最前 -->
            <template v-if="showReportTab">
              <div
                v-if="commentInfoData.length > 0"
                class="view-review__report-tab"
                :class="{ open: showReportTabList }"
                @click="toggleReportTabList"
              >
                <div class="tabname">
                  {{ language.SHEIN_KEY_PWA_15207 }} ({{ reportList.length }})
                </div>
                <span
                  class="iconfont"
                  :class="{ 'icon-shang': showReportTabList, 'icon-down': !showReportTabList }"
                ></span>
              </div>
              <div
                v-for="(comment, index) in reportList"
                v-show="showReportTabList || commentInfoData.length === 0"
                :key="comment.comment_id"
                :class="{
                  'j-expose__page_reviews__item': true,
                  'with-padding-bottom':
                    !shouldHideReviewTipsShow && index !== reportList.length - 1
                }"
                :data-expose-id="getExposeId(index, comment.comment_id)"
              >
                <div
                  v-expose="getAnalysisData({
                    member_size: comment.member_size,
                    member_cat_size: comment.member_cat_size,
                    content_tag: comment.content_tag,
                    comment_image: comment.comment_image,
                    review_type: 2,
                    language_flag: comment.language_flag,
                    commentId: comment.comment_id, 
                    commentPage: comment.page, 
                    index: Number(index) + 1,
                    outStoreReview: '',
                  })"
                  class="j-expose__page_reviews__item-con"
                >
                  <Comment
                    :reviewPhotoType="reviewPhotoType"
                    :innerSizeIsFold="innerSizeIsFold"
                    :watermark-config="watermarkConfig"
                    :index="index"
                    :comment-data="comment"
                    :position="'inner'"
                    :show-bra-size="isShowBraSize"
                    :language="language"
                    :has-fit="hasFit"
                    :size-config="commentMemberSizeConfig"
                    :comment-translate-config="commentTranslateConfig"
                    :is-batch-translate="isBatchTranslate"
                    :is-auto-translate="isAutoTranslate"
                    :translate-loading="translateloaing"
                    :curr-translate-lang="currTranslateLang"
                    :show-report="true"
                    :skc="detail.goods_sn"
                    :show-point-program="showPointProgram"
                    :analysis="{ activity_from: 'page_goods_reviews', location: 1 }"
                    :scroll-container="'.j-review-scroll-ctn'"
                    :comment-date-display-new="commentDateDisplayNew"
                    :isDetailBffv1="isDetailBffv1"
                    @sizeShowAll="sizeShowAllHandle($event, 'inner')"
                    @contentShowAll="contentShowAllHandle($event, 'inner')"
                    @showOriginComment="showOriginComment($event, 'inner')"
                    @closeTranslate="closeTranslateHandle($event, 'inner')"
                    @imageClick="imageClickHandle($event, 'inner')"
                    @clickTranslate="clickTranslateHandle($event, 'inner')"
                    @changeTranslateLanguage="changeTranslateLanguage($event, 'inner')"
                    @likeClick="likeClickHandle($event, comment, 'inner')"
                  />
                </div>
              </div>
            </template>

            <!-- 进行排序且仅有试用报告的提示 -->
            <div
              v-if="showReportTab && commentInfoData.length > 0"
              class="view-review__report-tab"
              :class="{ noborder: allList.length && isBatchTranslate }"
            >
              <div class="tabname">
                {{ language.SHEIN_KEY_PWA_15163 }}
              </div>
            </div>

            <!-- 普通评论批量翻译 -->
            <div
              v-if="allList.length && isBatchTranslate"
              class="batch-translate"
              :class="{ notop: (showReportTab && commentInfoData.length > 0) || isRomwe , 'label-top': showReviewLabel, 'filter-top': showNewReviewFilter}"
            >
              <p class="batch-translate__text">
                {{ language.SHEIN_KEY_PWA_18796 }}
              </p>
              <p
                class="batch-translate__text batch-translate__text-blue"
                @click="showTranslateLang = true"
              >
                {{ languageMap[defaultLang] }}
                <i class="suiiconfont sui_icon_more_down_16px"></i>
              </p>
              <div
                v-expose="{id: '1-6-2-93', code: 'reset-comment-events'}"
                class="batch-translate__handler"
                :class="[
                  isOpen === 2 ? 'batch-translate__handler-open' : 'batch-translate__handler-close'
                ]"
                @click="handleTranslate"
              >
                <template v-for="item in 2">
                  <div
                    :key="item"
                    :class="{ 'batch-translate__active': isOpen == item }"
                  ></div>
                </template>
              </div>
            </div>

            <!-- 非排序时展示的试用报告 + 普通评论 -->
            <div
              v-for="(comment, index) in allList"
              :key="`item-${comment.comment_id}-${onlyLocalStatus ? 1 : 0}`"
              :class="[
                tempClass,
                'j-expose__page_reviews__item',
                !shouldHideReviewTipsShow &&
                  index === allList.length - 1 &&
                  !showOutStoreReview &&
                  'with-padding-bottom',
                isAbtCommentAntiSpiderShowToLogin &&
                  index === allList.length - 1 &&
                  !showOutStoreReview &&
                  'fold-last-comment'
              ]"
              :data-expose-id="getExposeId(index, comment.comment_id)"
            >
              <div
                v-expose="getAnalysisData({ 
                  member_size: comment.member_size,
                  member_cat_size: comment.member_cat_size,
                  content_tag: comment.content_tag,
                  comment_image: comment.comment_image,
                  language_flag: comment.language_flag,
                  commentId: comment.comment_id, 
                  commentPage: comment.page, 
                  index: getCommentIndex(index),
                  outStoreReview: '',
                  sameFlag: comment.same_flag
                })"
                class="j-expose__page_reviews__item-con"
              >
                <Comment
                  :reviewPhotoType="reviewPhotoType"
                  :innerSizeIsFold="innerSizeIsFold"
                  :watermark-config="watermarkConfig"
                  :index="index"
                  :comment-data="comment"
                  :position="'inner'"
                  :show-bra-size="isShowBraSize"
                  :language="language"
                  :has-fit="hasFit"
                  :size-config="commentMemberSizeConfig"
                  :comment-translate-config="commentTranslateConfig"
                  :translate-loading="translateloaing"
                  :is-batch-translate="isBatchTranslate"
                  :is-auto-translate="isAutoTranslate"
                  :curr-translate-lang="currTranslateLang"
                  :show-report="true"
                  :analysis="{ activity_from: 'page_goods_reviews', location: 1 }"
                  :skc="detail.goods_sn"
                  :cat-id="detail.cat_id"
                  :scroll-container="'.j-review-scroll-ctn'"
                  :show-point-program="showPointProgram"
                  :comment-position="getCommentIndex(index)"
                  :mark-single-local-comment="markSingleLocalComment"
                  :comment-date-display-new="commentDateDisplayNew"
                  :isDetailBffv1="isDetailBffv1"
                  @sizeShowAll="sizeShowAllHandle($event, 'inner')"
                  @contentShowAll="contentShowAllHandle($event, 'inner')"
                  @showOriginComment="showOriginComment($event, 'inner')"
                  @closeTranslate="closeTranslateHandle($event, 'inner')"
                  @imageClick="imageClickHandle($event, 'inner')"
                  @clickTranslate="clickTranslateHandle($event, 'inner')"
                  @changeTranslateLanguage="changeTranslateLanguage($event, 'inner')"
                  @likeClick="likeClickHandle($event, comment, 'inner')"
                />
              </div>
            </div>

            <!-- 站外评论列表 -->
            <template v-if="showOutStoreReview">
              <!-- 普通评论与站外评论衔接提示 -->
              <div
                v-if="!onlyOutStoreReview"
                class="review-modal__noMoreCommentTip"
              >
                <h3>{{ language.SHEIN_KEY_PWA_24445 }}</h3>
              </div>

              <div
                v-for="(comment, index) in outStoreReviewList"
                :key="'storeReview-' + comment.comment_id"
                :class="[
                  tempClass,
                  'j-expose__page_reviews__item',
                  !shouldHideReviewTipsShow && index === outStoreReviewList.length - 1
                    ? 'with-padding-bottom'
                    : '',
                  isAbtCommentAntiSpiderShowToLogin && index === outStoreReviewList.length - 1
                    ? 'fold-last-comment'
                    : ''
                ]"
                :data-expose-id="getExposeId(allList.length + index, comment.comment_id)"
              >
                <div
                  v-expose="getAnalysisData({
                    member_size: comment.member_size,
                    member_cat_size: comment.member_cat_size,
                    content_tag: comment.content_tag,
                    comment_image: comment.comment_image,
                    language_flag: comment.language_flag,
                    review_type: 1,
                    commentId: comment.comment_id, 
                    commentPage: comment.page, 
                    index: getCommentIndex(allList.length + index),
                    outStoreReview: '1',
                  })"
                  class="j-expose__page_reviews__item-con"
                >
                  <Comment
                    :watermark-config="watermarkConfig"
                    :index="index"
                    :comment-data="comment"
                    :position="'inner'"
                    :language="language"
                    :comment-translate-config="commentTranslateConfig"
                    :is-auto-translate="isAutoTranslate"
                    :curr-translate-lang="currTranslateLang"
                    :show-report="true"
                    :analysis="{ activity_from: 'page_goods_reviews', location: 1 }"
                    :skc="detail.goods_sn"
                    :cat-id="detail.cat_id"
                    :scroll-container="'.j-review-scroll-ctn'"
                    :comment-position="getCommentIndex(allList.length + index)"
                    :comment-date-display-new="commentDateDisplayNew"
                    :isDetailBffv1="isDetailBffv1"
                    @sizeShowAll="sizeShowAllHandle"
                    @contentShowAll="contentShowAllHandle($event, 'inner')"
                    @closeTranslate="closeTranslateHandle($event, 'inner')"
                    @clickTranslate="clickTranslateHandle($event, 'inner')"
                    @changeTranslateLanguage="changeTranslateLanguage($event, 'inner')"
                  />
                </div>
              </div>
            </template>

            <!-- 命中abt未登陆情况下引导登陆 -->
            <div
              v-if="isAbtCommentAntiSpiderShowToLogin"
              v-expose="{ id: '1-6-2-60' }"
              v-tap="{ id: '1-6-2-61' }"
              class="comment-reviews__tologin with-padding-bottom"
              @click="handleShowCommentToLogin"
            >
              <div class="comment-reviews__tologin_text">
                <span>{{ language.SHEIN_KEY_PWA_25934 }}</span>
                <span class="icon suiiconfont sui_icon_more_right_16px_2"></span>
              </div>
            </div>

          <!-- loading 优化 -->
          <!-- <s-loading :show="commentScrollLoading" /> -->
          </div>

          <!-- Some reviews have been folded for you -->
          <div
            v-if="shouldHideReviewTipsShow"
            class="common-reviews__fuzzy-tips with-padding-bottom"
          >
            <p>{{ language.SHEIN_KEY_PWA_25935 }}</p>
          </div>
        </div>
        <div
          class="newstyle-uptt mshe-z-returnTop"
          :style="{ display: upShow ? 'block' : 'none' }"
          @click.stop="toUp"
        >
          <i class="iconfont icon-top1"></i>
        </div>
        <!-- 评论页加车 -->
        <AddBag
          ref="compopAddHook"
          class="comment-add-bag"
          :is-review="true"
        />
      </div>
    </s-drawer>
    <!--reviews filter 抽屉筛选 图片&评分-->
    <s-drawer
      :visible="drawerFilterShow"
      direction="rtl"
      :show-close-animation="showCloseAnimation"
    >
      <div style="height: 100%">
        <header class="list-filter__header">
          <i
            class="suiiconfont sui_icon_nav_close_24px j-clear-history-hash"
            @click="close"
          ></i>
          <h3>{{ language.SHEIN_KEY_PWA_15044 }}</h3>
        </header>
        <div
          class="list-filter__contain"
          @click.stop
        >
          <div class="list-filter__inner">
            <div class="filter-top">
              <em>{{ language.SHEIN_KEY_PWA_15094 }}</em>
            </div>
            <div class="list-filter__con mshe-clearfix">
              <template v-for="(item, index) in reviewsCon">
                <div
                  :key="'filter' + index"
                  class="list-filter__con-item"
                >
                  <span
                    class="filter-pic-span"
                    :class="{ active: withPicture == item.value, loading: commentLoading }"
                    @click="choosePicture(item)"
                    v-html="item.name"
                  ></span>
                </div>
              </template>
            </div>
          </div>

          <div class="list-filter__inner">
            <div class="filter-top">
              <em>{{ language.SHEIN_KEY_PWA_15332 }}</em>
            </div>
            <div class="list-filter__con mshe-clearfix">
              <template v-for="(item, index) in rating">
                <div
                  :key="index"
                  class="list-filter__con-item"
                >
                  <span
                    class="filter-rating-span"
                    :class="{ active: comment_rank === item, loading: commentLoading }"
                    @click="chooseRating(item)"
                  >
                    {{ item }}
                    <i class="suiiconfont sui_icon_star_5_12px_2"></i>
                  </span>
                </div>
              </template>
            </div>
          </div>
          <div
            v-if="reportList.length > 0"
            class="list-filter__inner"
          >
            <div class="filter-top">
              <em>{{ language.SHEIN_KEY_PWA_16868 }}</em>
            </div>
            <div class="list-filter__con mshe-clearfix">
              <div class="list-filter__con-item">
                <span
                  class="filter-pic-span"
                  :class="{ active: onlyShowReport }"
                  @click="chooseTrialReport"
                >
                  {{ language.SHEIN_KEY_PWA_15207 }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="list-filter__bottom list-footer">
          <div class="filter-bot-left">
          </div>
          <div class="filter-bot-right">
            <i
              class="iconfont icon-delete"
              @click.stop="handleDelete"
            ></i>
            <s-button
              :type="['primary']"
              :class="{
                'mshe-btn-black': true,
                'j-clear-history-hash': true,
                'with-loading': commentLoading
              }"
              da-event-click="1-6-2-1"
              :data-size-id="commentFilterAttrSizeId"
              :data-image-type="getImageType()"
              :data-star="comment_rank"
              :data-review-num="reviewNum"
              @click="close('done')"
            >
              {{ language.SHEIN_KEY_PWA_15042 }}
              <s-loading :show="commentLoading" />
            </s-button>
          </div>
        </div>
      <!-- <s-loading
          class="list-filter__loading"
          :show="commentLoading"
          :container-cover-style="{
            position: 'absolute',
            top: '1.07rem'
          }"
          type="curpage"
        /> -->
      </div>
    <!--reviews load-->
    <!-- <s-loading
        :show="status.commentLoading"
        :container-cover-style="{
          position: 'absolute',
          top: '1.07rem'
        }"
        type="curpage"
      /> -->
    </s-drawer>

    <!--comment translate single -->
    <s-drawer
      :visible.sync="translateModelIsShow"
      direction="btt"
    >
      <header
        class="translate-drawer__header j-translate-list-header"
        tabindex="0"
        :aria-label="language.SHEIN_KEY_PWA_17083"
        :role="language.SHEIN_KEY_PWA_15202"
      >
        {{ language.SHEIN_KEY_PWA_17083 }}
      </header>
      <div class="translate-drawer__list">
        <ul>
          <template
            v-for="(language, index) in commentTranslateConfig[translateModel.comment_lang]"
          >
            <li
              :key="index"
              :class="{ active: language === translateModel.lang }"
              @click="changeLanguage(language)"
            >
              <button
                tabindex="0"
                role="text"
                :aria-label="languageMap[language]"
              >
                {{ languageMap[language] }}
              </button>
              <i
                v-if="language === translateModel.lang"
                class="iconfont icon-normal-checked"
              ></i>
            </li>
          </template>
        </ul>
      </div>
      <button
        class="translate-drawer__cancel"
        @click="translateModelIsShow = false"
      >
        {{ language.SHEIN_KEY_PWA_15498 }}
      </button>
    </s-drawer>
    <!--comment translate batch-->
    <s-drawer
      :visible.sync="showTranslateLang"
      direction="btt"
    >
      <header
        class="translate-drawer__header j-translate-list-header"
        tabindex="0"
        :aria-label="language.SHEIN_KEY_PWA_17083"
        :role="language.SHEIN_KEY_PWA_15202"
      >
        {{ language.SHEIN_KEY_PWA_17083 }}
      </header>
      <div class="translate-drawer__list">
        <ul>
          <template v-for="(language, index) in languageConfigSet">
            <li
              :key="index"
              :class="{ active: language === defaultLang }"
              @click="changeBatchLanguage(language)"
            >
              <button
                tabindex="0"
                role="text"
                :aria-label="languageMap[language]"
              >
                {{ languageMap[language] }}
              </button>
              <i
                v-if="language === defaultLang"
                class="iconfont icon-normal-checked"
              ></i>
            </li>
          </template>
        </ul>
      </div>
      <button
        class="translate-drawer__cancel"
        @click="showTranslateLang = false"
      >
        {{ language.SHEIN_KEY_PWA_15498 }}
      </button>
    </s-drawer>
    <!-- 翻译失败 -->
    <SDialog
      :visible="translateError"
      class="modal-diglog"
    >
      <div class="modal-diglog__content">
        <div>
          <i class="iconfont icon-fail"></i>
          {{ language.SHEIN_KEY_PWA_17048 }}
          <div slot="footer"></div>
        </div>
      </div>
    </SDialog>
  </div>
</template>
<script>
import Comment from '../Comment'
import ReviewLabel from '../ReviewLabel'
import RatePanel from '../RatePanel'
import SortRecommed from './components/SortRecommed'
import StarFilterPanel from './components/StarFilterPanel'
import NewSortFilter from './components/NewSortFilter'
import SortDownList from './components/SortDownList'
import SortFilter from './components/SortFilter'
import ShowLocalSwitch from './components/ShowLocalSwitch'
import ReviewFilterPanel from './components/ReviewFilterPanel'
import SortRomweV2 from './components/SortRomweV2'
import PictureRatingFilterRomwe from './components/PictureRatingFilterRomwe'

import {
  Dialog as SDialog,
  Button as SButton,
  TitleNav as STitleNav,
  TitleNavItem as STitleNavItem
} from '@shein/sui-mobile'

import AddBag from 'public/src/pages/goods_detail/components/top/innerComponents/AddToCartBar'
import commentMixin from '../../mixin/comment'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { transformImg } from '@shein/common-function'
import { COMMENT_NO_LOGIN_SHOW_LIMIT, SHOW_CAT_ID } from '../../constants'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { isDetailBffApi } from 'public/src/pages/goods_detail/utils/bffUtil/index.js'
import { Icon } from '@shein-aidc/icon-vue2'
let daEventExpose = daEventCenter.getExposeInstance()
const { langPath } = gbCommonInfo

export default {
  name: 'CommentPop',
  components: {
    Icon,
    Comment,
    ReviewLabel,
    RatePanel,
    SortDownList,
    SortRecommed,
    StarFilterPanel,
    NewSortFilter,
    SortFilter,
    ShowLocalSwitch,
    AddBag,
    ReviewFilterPanel,
    SortRomweV2,
    PictureRatingFilterRomwe,
    SDialog,
    SButton,
    STitleNav,
    STitleNavItem
  },
  mixins: [commentMixin],
  data() {
    return {
      isShow: false,
      onceHaveReport: false,
      translateModelIsShow: false,
      showReportTabList: false,
      isShowBraSize: true,
      upShow: false,
      rating: ['5', '4', '3', '2', '1'],
      outerScroll: 0,
      firstOpen: true,
      languageMap: {
        en: 'English',
        ar: 'العربي',
        de: 'Deutsch',
        fr: 'Français',
        es: 'Español',
        it: 'Italiano',
        ru: 'Pусский',
        th: 'ภาษาไทย',
        nl: 'Nederlands',
        vi: 'Tiếng Việt',
        he: 'עברית',
        'zh-tw': '繁體中文',
        'pt-br': 'Português（Brasil）',
        sv: 'Svenska',
        pl: 'Polski',
        id: 'Bahasa Indonesia',
        tr: 'Türk',
        'pt-pt': 'Português（Portugal）',
        ja: '日本語',
        ko: '한국어',
        'cs-cz': 'Čeština',
        'el-gr': 'Ελληνικά'
      },
      tempClass: 'goods-detail__review-single-item',
      isOpen:
        typeof localStorage !== 'undefined' && localStorage.getItem('isOpen')
          ? Number(localStorage.getItem('isOpen'))
          : 2, // 1off 2on
      showTranslateLang: false,
      // 仅有站外评论提示框展示状态
      storeCommentTipShow: false,
      isLogin: isLogin(),
      COMMENT_NUM_LIMIT_LOGIN_AND_FILTER: 200,
      isShowRank: 0
    }
  },
  provide() {
    return {
      RELATIVE_SPU: this.detail?.productRelationID || '',
      PAGE_FROM: 'goods_detail'
    }
  },
  computed: {
    ...mapState('newProductDetail/common', ['saveStatus', 'closeCommentNeedScrollRecomend']),
    ...mapState('newProductDetail/CommentPopup', [
      'localTop',
      'onlyLocalStatus',
      'tag_id',
      'sort',
      // 'commentFilterColorId',
      'commentFilterGoodsId',
      'commentFilterGoodsSn',
      'commentFilterAttrSizeId',
      'withPicture',
      'comment_rank',
      'onlyShowReport',
      'curPage',
      // 以上是筛选数据 ⬆️
      'viewComment',
      'viewCommentFrom',
      'commentLoading',
      'commentScrollLoading',
      'drawerFilterShow',
      'onlyLocalStatus',
      'reviewsTags',
      'onlyShowReport',
      'commentInfoTotal',
      'commentNumStr',
      'noMoreComment',
      'commentInfoData',
      'reportList',
      'outStoreReviewList',

      'currTranslateLang',
      'defaultLang',
      'translateModel',
      'translateloaing',
      'translateError',
      'isAutoTranslate'
    ]),
    ...mapGetters('newProductDetail', ['fsAbt']),
    ...mapGetters('newProductDetail/CommentPopup', [
      'allListPure',
      'commentFilterAttrSize',
      'hasFilterCondition',
      'languageConfigSet',
      'onlyShowSameItem'
    ]),
    ...mapGetters('newProductDetail/Middle', [
      'commentMemberSizeConfig',
      'showPointProgram',
      'commentTranslateConfig'
    ]),
    ...mapGetters('newProductDetail/common', [
      'pageCommonInfo',
      'language',
      'detail',
      'watermarkConfig',
      'hasFit',
      'detail',
      'relationProducts',
      'productRelationID',
      'isBatchTranslate',
      'sizeRule'
    ]),
    ...mapGetters('newProductDetail/Reviews', [
      'reviewsCon',
      'sortListMap',
      'showRatePanel',
      'showLocalReviews',
      'hasCommentsToSize',
      'displayCommentSum',
      'markSingleLocalComment',
      'commentDateDisplayNew'
    ]),
    ...mapGetters('newProductDetail/ColorBox', ['colorBoxInfo']),
    isDetailBffv1() {
      return isDetailBffApi('v1')
    },
    isRomwe() {
      return this.pageCommonInfo.WEB_CLIENT === 'romwe'
    },
    isShowValuableReviews() {
      // 站外评论不显示
      return this.fsAbt?.Reviewchange?.param?.Comment_des === 'Yes' && !this.onlyOutStoreReview
    },
    reviewPhotoType() {
      let Reviewpicture = this.fsAbt?.Reviewchange?.param?.Reviewpicture
      return Reviewpicture === 'Small_num' ? 'Smallpic' : Reviewpicture === 'Vary' ? 'Varypic' : 'None'
    },
    innerSizeIsFold() {
      return this.fsAbt?.Reviewchange?.param?.Sizeinfo === 'Fold'
    },
    reviewTagLine() {
      return this.fsAbt?.Reviewchange?.param?.Reviewtag?.toLowerCase() || 'three'
    },
    showNewReviewcard() {
      return ['True_to_size_only', 'True_to_size_both'].includes(this.fsAbt?.Reviewchange?.param?.Reviewcard_display)
    },
    showNewReviewFilter() {
      return this.fsAbt?.Reviewchange?.param?.Review_filter === 'Expose_filters' 
    },
    // 是否展示底部的部分评论被隐藏提示
    shouldHideReviewTipsShow() {
      const {
        reviewNum,
        noMoreComment,
        // isAbtCommentAntiSpider,
        COMMENT_NUM_LIMIT_LOGIN_AND_FILTER,
        hasFilterCondition
      } = this

      // 未筛选时，评论数量>1000展示折叠文案
      // 筛选后，评论数>200折叠
      if (
        // isAbtCommentAntiSpider &&
        hasFilterCondition &&
        noMoreComment &&
        reviewNum >= COMMENT_NUM_LIMIT_LOGIN_AND_FILTER
      ) {
        return true
      }

      // 评论总数不超过1000，则不展示隐藏提示
      // * total是一个已经被模糊的数值，在此只需要大于500即可
      if (!(reviewNum > 900)) {
        return false
      }
      if (noMoreComment) {
        return true
      }
      return false
    },
    isAbtCommentAntiSpiderShowToLogin() {
      const { noMoreComment, isLogin, allListPure } = this
      // 命中abt 未登陆同时评论数据还未请求完
      if (
        !isLogin &&
        allListPure.length >= COMMENT_NO_LOGIN_SHOW_LIMIT &&
        !noMoreComment
      ) {
        return true
      }
      return false
    },
    // 是否展示标签筛选栏
    showReviewLabel() {
      const { reviewsTags, onlyOutStoreReview } = this
      return reviewsTags.length && !this.onlyShowReport && !onlyOutStoreReview
    },
    newSizeRule() {
      const { sizeRule } = this
      let country = window?.localStorage.getItem('last_select_country')
      return {
        size_rule_list: country || country !== 'default'
          ? gbCommonInfo.contextForSSR?.localsize?.size_rule_list?.[country]
          : sizeRule.size_rule_list
      }
    },
    sizeRuleShow() {
      const { newSizeRule = {}, detail } = this
      const { size_rule_list = {} } = newSizeRule
      if (Object.keys(size_rule_list).length === 0) {
        return false
      }
      return detail.brand !== 'Luvlette'
    },
    sizeCorrespondMap() {
      const { sizeRuleShow, newSizeRule } = this
      if (!sizeRuleShow) {
        return null
      }
      const map = Object.create(null)
      const { size_rule_list } = newSizeRule
      for (const key in size_rule_list) {
        const { name, correspond } = size_rule_list[key] || {}
        if (name) {
          map[name] = correspond
        }
      }
      return map
    },
    // allListPure() {
    //   const allList = []
    //   const reportList = [...this.reportList]
    //   const commentData = [...this.commentInfo.data]
    //   const colorsInfo = this.colorBoxInfo?.colorsInfo || []
    //   if (colorsInfo.length) {
    //     reportList.forEach(i => {
    //       // 试用的颜色属性，跟当前页面颜色属性是一致的，在这里赋值
    //       i.color = colorsInfo.find(g => g.goods_sn == i.sku)['goods_title'] || ''
    //     })
    //   }
    //   if (this.commentInfo.onlyShowTrialReport) {
    //     return reportList
    //   }
    //   if (this.showReportTab || this.commentInfo.tag_id) {
    //     return commentData
    //   }
    //   if (this.commentInfo.data.length > 0) {
    //     for (let i = 0; i < this.commentInfo.data.length; i++) {
    //       if (reportList.length === 0) {
    //         return allList.concat(commentData)
    //       }
    //       if (allList.length % 3 === 0) {
    //         allList.push(reportList.shift())
    //       }
    //       allList.push(commentData.shift())
    //     }
    //     if (this.noMoreCofmment) {
    //       return allList.concat(reportList)
    //     }
    //   } else {
    //     return reportList
    //   }
    //   return allList
    // },
    allList() {
      const { isAbtCommentAntiSpiderShowToLogin, allListPure } = this
      if (!isAbtCommentAntiSpiderShowToLogin || allListPure.length <= COMMENT_NO_LOGIN_SHOW_LIMIT) {
        return allListPure
      }
      return allListPure.slice(0, COMMENT_NO_LOGIN_SHOW_LIMIT)
    },
    showReportTab() {
      return this.sort && this.sort !== 'default' && !this.onlyShowReport && this.reportList.length
    },
    reviewNum() {
      const { tag_id } = this
      if (this.onlyShowReport) {
        return this.reportList.length || 0
      }
      if (tag_id) {
        return this.commentInfoTotal
      }

      return this.commentInfoTotal + this.reportList.length
    },

    // 展示的评论总数
    displaySum() {
      if (this.onlyShowReport) {
        return this.reportList.length || 0
      }
      return this.commentNumStr
    },
    filterAttrs() {
      const commentFilterAttrSize = this.commentFilterAttrSize
      if (!commentFilterAttrSize.length) return []
      // 造一个filter data
      const filterData = [
        {
          attr_id: (commentFilterAttrSize[0] && commentFilterAttrSize[0].attr_id) || '',
          attr_name: (commentFilterAttrSize[0] && commentFilterAttrSize[0].attr_name) || '',
          attr_values: commentFilterAttrSize
        }
      ]
      return filterData
    },
    productColor: function () {
      const products = [{ ...this.detail }].concat(this.relationProducts || [])

      if (!products.length) return []

      const productColor = products.map(item => {
        const { color_image, goods_id, goods_sn = '' } = item
        let color_text = ''
        // let color_id = ''
        if (item?.mainSaleAttribute?.[0]) {
          const color = item.mainSaleAttribute[0]
          color_text = color.attr_value || ''
          // color_id = color.attr_id == 27 ? color.attr_value_id || '' : ''
        }

        return {
          color_text,
          goods_color_image: color_image,
          goods_id,
          goods_sn,
          // color_id
        }
      })

      return productColor
    },
    colorSizeNumber() {
      return [!!this.commentFilterGoodsId, !!this.commentFilterAttrSizeId].filter(Boolean).length
    },
    cartSumQuantity() {
      return this.$store.state.cartInfo.sum
    },
    // 是否展示站外评论
    showOutStoreReview() {
      const { outStoreReviewList, isBatchTranslate } = this
      if (!outStoreReviewList.length || isBatchTranslate) {
        return false
      }

      if (this.hasFilterCondition) {
        return false
      }

      return this.noMoreComment
    },
    // 仅存在站外评论
    onlyOutStoreReview() {
      const { allList, showOutStoreReview } = this
      return allList.length === 0 && showOutStoreReview
    },
    isIos() {
      return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    },
    // 苹果下左滑关闭弹窗会有两次关闭的动画，所以不显示弹窗动画
    showCloseAnimation() {
      return !this.isIos
    }
  },
  watch: {
    isShow(val) {
      if (val  && !this.onlyOutStoreReview) {
        //expose_product_filter 商品筛选项曝光
        daEventCenter.triggerNotice({
          daId: '1-6-2-74'
        })
        //expose_sort_type 排序方式曝光
        daEventCenter.triggerNotice({
          daId: '1-6-2-79'
        })
       
        if(this.showNewReviewFilter) {
          //expose_rating_filter 星级筛选项曝光
          daEventCenter.triggerNotice({
            daId: '1-6-2-83'
          })
          //expose_picture_filter  图片筛选项曝光
          daEventCenter.triggerNotice({
            daId: '1-6-2-77'
          })
          //expose_trial_filter 试用报告筛选项曝光
          this.reportList.length && daEventCenter.triggerNotice({
            daId: '1-6-2-84'
          })
        }
        
        // 重置曝光事件
        daEventExpose.reset('reset-comment-events')
        daEventExpose.reset('reset-translate-events')
        // 评论详情标签更多按钮曝光
        if(this.showReviewLabel){
          this.$refs.reviewLabel?.exposeMoreIcon()
        }
      }
    },
    'reportList.length'(val) {
      this.onceHaveReport = this.onceHaveReport || !!val
      if(val && this.showNewReviewFilter && !this.onlyOutStoreReview && !this.onceHaveReport) {
        // expose_trial_filter
        daEventCenter.triggerNotice({
          daId: '1-6-2-84'
        })
      }
    },
    viewComment(val) {
      this.isShow = val
      this.updateNoScroll()
      if (val) {
        daEventCenter.triggerNotice({
          daId: '1-6-2-62'
        })
        if(this.viewCommentFrom === 'tag') {
          // 从tag进来重新请求数据
          this.initCommentPopDate(false)
        }
      }

      this.$nextTick(() => {
        if (val) {
          this.scroll_review_top()
          setTimeout(() => {
            $('.j-icon-back').focus()
            this.tempClass = 'goods-detail__review-single-item'
          }, 300)
          if (!this.exposeReviewBind) {
            this.exposeReviewBind = true
          }

          // 通过评论数量判断，其高度小于窗口可滚动触发拉取下一页数据的高度时，认为无更多评论
          if (this.reviewNum < 3) {
            this.updateNoMoreComment(true)
          }

          document
            .querySelector('.j-review-scroll-ctn')
            ?.addEventListener('scroll', this.scroll_review_top)
        } else {
          if (this.outerScroll) {
            document.documentElement.scrollTop = this.outerScroll
            this.outerScroll = 0
          }
          this.toUp()
          if (this.closeCommentNeedScrollRecomend) {
            this.setGoodsDetailScroll()
            this.updateCloseCommentNeedScrollRecomend(false)
          }

          document
            .querySelector('.j-review-scroll-ctn')
            ?.removeEventListener('scroll', this.scroll_review_top)
        }
      })
    },
    translateModelIsShow(val) {
      if (!val) {
        this.updateTranslateModel({
          show: false
        })
      }
    },
    drawerFilterShow(newVal) {
      this.setPopPageFixed(!!newVal)
      if (newVal) {
        this.outerScroll = $('body')
          .css('top')
          .replace(/[-|(px)]/g, '')
      } else {
        daEventCenter.triggerNotice({
          daId: '1-6-2-21',
          extraData: {
            // color_id: this.commentFilterColorId,
            size_id: this.commentFilterAttrSizeId,
            image_type: this.getImageType() ? 'with_picture' : '',
            star: this.comment_rank,
            review_num: this.reviewNum
          }
        })

      }
    },
    detail: function (newVal) {
      this.isShowBraSize = newVal && newVal.cat_id && SHOW_CAT_ID.includes(Number(newVal.cat_id))
    },
    'translateModel.show'(val) {
      this.translateModelIsShow = val
      if (val) {
        setTimeout(() => {
          $('.j-translate-list-header').focus()
        }, 500)
      } else {
        this.$nextTick(() => {
          if (this.viewComment) {
            try {
              window.$commentel.querySelector('.item-see-translate button').focus()
            } catch (e) {
              /**/
            }
          } else {
            $('.j-scroll-expose-translate').focus()
          }
        })
      }
    },
    onlyLocalStatus() {
      // 切换是否展示本地评论时，重新请求评论数据
      this.resetCommentFilterData()
      this.fetchAllCommentInfo({ firstLoad: true })
    },
    // 评论列表底部模糊提示曝光上报
    shouldHideReviewTipsShow: {
      handler(val) {
        if (val) {
          daEventCenter.triggerNotice({
            daId: '1-6-2-51'
          })
        }
      }
    },
    productRelationID() {
      this.initCommentPopDate()
    }
  },
  mounted() {
    this.initCommentPopDate(false)
    this.getApolloConfig('COMMENT_NUM_LIMIT_LOGIN_AND_FILTER').then(res => {
      this.COMMENT_NUM_LIMIT_LOGIN_AND_FILTER = res['COMMENT_NUM_LIMIT_LOGIN_AND_FILTER'] || 200
    })
    if (this.viewComment) {
      this.isShow = true
      daEventCenter.triggerNotice({
        daId: '1-6-2-62'
      })
      this.updateNoScroll()
      this.$nextTick(() => {
        document
          .querySelector('.j-review-scroll-ctn')
          ?.addEventListener('scroll', this.scroll_review_top)
      })
    }
    if (this.translateModel.show) {
      this.translateModelIsShow = true
    }
  },
  destroyed() {
    this.isShow = false
    this.updateNoScroll()
    this.resetAllCommentData()
    document
      .querySelector('.j-review-scroll-ctn')
      ?.removeEventListener('scroll', this.scroll_review_top)
  },
  methods: {
    ...mapActions('newProductDetail/common', ['setGoodsDetailScroll']),
    ...mapMutations('newProductDetail/CommentPopup', [
      'resetCommentFilterData',
      'updateOnlyShowReport',
      'updateCommentFilterData',
      'updateDrawerFilterShow',
      'updateCurrTranslateLang',
      'updateShowRatingList',
      'updateShowColorSizeList',
      'updateTranslateModel',
      'updateDefaultLang',
      'updateIsAutoTranslate',
      'updateNoMoreComment',
      'resetAllCommentData'
    ]),
    ...mapMutations('newProductDetail/common', ['updateCloseCommentNeedScrollRecomend']),
    ...mapActions('newProductDetail/CommentPopup', [
      'fetchAllCommentInfo',
      'initOutStoreComments',
      'fetchBatchTranslateForhadLoadData'
    ]),
    ...mapActions('newProductDetail', ['getApolloConfig']),
    initCommentPopDate(needReset = true) {
      needReset && this.resetAllCommentData() // 某些场景不需要重置筛选状态
      this.fetchAllCommentInfo({
        cb: () => {
          setTimeout(() => {
            this.localReviewsToUp()
          }, 200)
        }
      })
      if (!isDetailBffApi('v2')) {
        this.initOutStoreComments()
      }
    },
    getIsShowRank(res) {
      this.isShowRank = res ? 1 : ''
    },
    // 在筛选栏出现时固定页面
    setPopPageFixed(isFix) {
      try {
        $('.j-review-scroll-ctn').css('overflow', isFix ? 'hidden' : '')
      } catch (err) {
        //
      }
    },
    // 根据 show 的值动态添加或移除 body 的类名， 控制页面的滚动
    updateNoScroll() {
      // 为了避免子 Drawer 在关闭时删除 `S-popup-parent__hidden` 类名而导致页面出现滚动条，
      // 在父 Drawer 显示情况下添加 `S-popup-parent__hidden-multi` 类名解决。
      const className = 'S-popup-parent__hidden-multi'
      const classList = document.querySelector('body').classList
      this.isShow ? classList.add(className) : classList.remove(className)
    },
    // 获取评论在列表中的位置
    getCommentIndex(index) {
      const { showReportTab, showReportTabList, reportList } = this
      let position = Number(index) + 1
      if (showReportTab && showReportTabList) {
        position += reportList.length
      }
      return position
    },
    handleOpen() {
      this.$nextTick(() => {
        this.firstOpen &&
          this.$refs['compopAddHook']?.setSaveBtn &&
          this.$refs['compopAddHook'].setSaveBtn(this.saveStatus)
        this.firstOpen = false
      })
      setTimeout(() => {
        this.localReviewsToUp()
      }, 200)
    },
    changeFilter(flag = false) {
      //expose_rating_filter 星级筛选项曝光
      flag && daEventCenter.triggerNotice({
        daId: '1-6-2-83'
      })
      //expose_picture_filter  图片筛选项曝光
      flag && daEventCenter.triggerNotice({
        daId: '1-6-2-77'
      })
      //expose_trial_filter 试用报告筛选项曝光
      flag && this.reportList.length && daEventCenter.triggerNotice({
        daId: '1-6-2-84'
      })
      this.updateDrawerFilterShow(flag)
      this.reviewExposeDate = Date.now()
    },
    clickFilterColor: function (color_id, title, goods_id, goods_sn) {
      // let color_id_filter = color_id === this.commentFilterColorId ? '' : color_id
      // if (+color_id_filter === 447) {
      //   // 多色条件下用goods_id筛选评论
      //   color_id_filter = '' // goods_id 用于更新选中状态，因为 color_id 可能存在组合
      // }
      this.updateCommentFilterData({
        tag_id: '',
        // commentFilterColorId: color_id_filter,
        commentFilterGoodsId: this.commentFilterGoodsId === goods_id ? '' : goods_id,
        commentFilterGoodsSn: this.commentFilterGoodsSn === goods_sn ? '' : goods_sn,
        curPage: 1,
        currSizeIndex: -1
      })

      this.fectMoreComment(() => {
        // if (color_id_filter) {
        // * FR-9325 1-6-2-18埋点迁移到新的筛选器中
        // }
      })

    },
    clickFilterSize(item) {
      this.updateCommentFilterData({
        curPage: 1,
        currSizeIndex: -1,
        tag_id: '',
        commentFilterAttrSizeId:
          this.commentFilterAttrSizeId === item.attr_value_id ? '' : item.attr_value_id
      })

      this.fectMoreComment(() => {
        if (item.active) {
          // * FR-9325 1-6-2-19埋点迁移到新的筛选器中
        }
      })

    },
    choosePicture(data) {
      this.showNewReviewFilter && this.withPicture !== data.value && daEventCenter.triggerNotice({
        daId: '1-6-7-20',
      })
      this.updateCommentFilterData({
        curPage: 1,
        tag_id: '',
        withPicture: this.withPicture === data.value ? '' : data.value
      })

      this.fectMoreComment(() => {})

    },
    chooseRating(value, multipleChoice) {
      let comment_rank = ''
      if(multipleChoice){
        if(!this.comment_rank.split(',').includes(value)){
          comment_rank = this.comment_rank ? this.comment_rank + `,` + value : value
          comment_rank = comment_rank.split(',').sort((a, b) => b - a).join(',')
        }else{
          comment_rank = this.comment_rank.split(',').filter(item => item != value).sort((a, b) => b - a).join(',')
        }
      }else{
        comment_rank = value
        if (this.comment_rank === value) comment_rank = ''
      }

      this.updateCommentFilterData({
        curPage: 1,
        tag_id: '',
        comment_rank
      })

      this.fectMoreComment(() => {
      })
    },
    chooseTrialReport() {
      this.updateOnlyShowReport(!this.onlyShowReport)
      
    },
    handleReviewTagClick(id) {
      this.updateCommentFilterData({
        tag_id: id === this.tag_id ? '' : id,
        curPage: 1,
        currSizeIndex: -1
      })
      this.fectMoreComment()
    },
    changeSort: function (item) {
      // 1-6-2-85
      daEventCenter.triggerNotice({
        daId: '1-6-2-85',
        extraData: {
          type: item.type
        }
      })
      this.updateCommentFilterData({
        curPage: 1,
        sort: item.sort
      })
      this.fectMoreComment()

      this.reviewExposeDate = Date.now()
      sa('send', { activity_name: item.biName })
      if(this.showNewReviewFilter) return
      this.$refs.sortFilter?.toggleSort()
      this.$refs.sortRomwe?.toggleSort()
    },
    handleShowLocalChange() {
      this.updateCommentFilterData({
        tag_id: ''
      })
    },
    changeLocalComment() {
      this.updateCommentFilterData({
        curPage: 1
      })
      this.fectMoreComment(null, true)
    },
    fetchMoreReviews: function () {
      if (this.onlyShowReport) return
      this.fectMoreComment()
    },
    resetColorSize() {
      this.updateCommentFilterData({
        commentFilterAttrSizeId: '',
        // commentFilterColorId: '',
        commentFilterGoodsId: '',
        currSizeIndex: -1,
        curPage: 1,
        tag_id: ''
      })

      this.fetchAllCommentInfo()
    },
    // 清除除color和size之外的筛选
    handleDelete(onlyRating = false) {
      this.updateCommentFilterData({
        withPicture: onlyRating ? this.withPicture : '',
        comment_rank: '',
        curPage: 1,
        onlyShowTrialReport: false
      })

      this.fetchAllCommentInfo()

      sa('send', { activity_name: 'click_filter_reset' })
    },
    fectMoreComment: function (cb) {
      const { isLogin } = this
      // 未登陆请求拦截，不允许请求超过限定总数的页数
      if (!isLogin) {
        if ((this.curPage - 1) * 20 >= COMMENT_NO_LOGIN_SHOW_LIMIT) {
          return
        }
      }
      this.fetchAllCommentInfo({
        cb
      })
    },
    close() {
      if(this.comment_rank){
        daEventCenter.triggerNotice({
          daId: '1-6-2-17',
          extraData: {
            type: this.comment_rank
          }
        })
      }
      if(this.withPicture){
        daEventCenter.triggerNotice({
          daId: '1-6-2-20'
        })
      }
      this.updateDrawerFilterShow(false)
      if (this.onlyShowReport) {
        daEventCenter.triggerNotice({
          daId: '1-6-2-29'
        })
      }
    },
    toggleReportTabList() {
      this.showReportTabList = !this.showReportTabList
    },
    getImageType() {
      return this.reviewsCon.find(item => item.value === this.withPicture) || {}
    },
    getExposeId(index, id) {
      return `page_review${id}_${index}_${this.reviewExposeDate}`
    },
    scroll_review_top() {
      if ($('.j-review-scroll-ctn').scrollTop() >= document.body.clientHeight * 2) {
        this.upShow = true
      } else {
        this.upShow = false
      }
    },
    changeLanguage(lang) {
      this.updateCurrTranslateLang(lang)
      localStorage.setItem('translate_lang', lang)
      if (this.translateModel.cb) this.translateModel.cb()
      this.updateTranslateModel({
        show: false,
        lang: '',
        cb: null
      })
    },
    changeBatchLanguage(lang) {
      localStorage.setItem('defaultLang', lang)
      this.updateDefaultLang(lang)
      this.showTranslateLang = false
      daEventCenter.triggerNotice({
        daId: '1-6-2-15',
        extraData: {
          type: 'batch',
          activity_from: 'page_goods_reviews',
          translate_language: this.currTranslateLang || this.defaultLang,
        }
      })
      this.fetchBatchTranslateForhadLoadData()
    },
    toUp() {
      $('.j-review-scroll-ctn').scrollTop(0)
    },
    localReviewsToUp() {
      if (!this.localTop || this.commentInfoTotal <= 3) return
      let distance = this.$refs['ShowLocalSwitchRef']?.getTop()
      document
        .getElementsByClassName('j-review-scroll-ctn')[0]
        .scrollTo({ top: distance, behavior: 'smooth' })
    },
    goBack() {
      this.updateShowRatingList(false)
      this.updateShowColorSizeList(false)
      this.$nextTick(() => {
        // ios14 过多的translateZ会导致页面崩溃
        this.tempClass = ''
        history.go(-1)
        this.toUp()
      })
    },
    handleTranslate() {
      this.isOpen = this.isOpen === 1 ? 2 : 1
      localStorage.setItem('isOpen', this.isOpen)
      this.updateIsAutoTranslate(this.isOpen === 2)
      daEventCenter.triggerNotice({
        daId: '1-6-2-13',
        extraData: {
          type: this.isOpen === 1 ? 'batch_off' : 'batch_on',
          activity_from: 'page_goods_reviews',
          translate_language: this.currTranslateLang || this.defaultLang,
        }
      })
    },
    bagClickEvt() {
      daEventCenter.triggerNotice({
        daId: '1-6-2-41',
        extraData: {
          review_location: 'page_review',
          num: this.cartSumQuantity
        }
      })
      markPoint('toNextPageClick', 'public')
      location.href = `${langPath}/cart`
    },
    handleShowCommentToLogin() {
      this.showLogin({
        cb: () => {
          this.isLogin = isLogin()
          // 登陆后更新评论
          this.fectMoreComment()
        }
      })
    },
    overFitValue(comment) {
      return (
        this.hasFit &&
        (+comment?.member_cat_size?.member_overall_fit ||
          +comment?.member_size?.member_overall_fit)
      )
    },
    miniMemberSize(comment) {
      let miniMemberSize = []
      let arrLength = this.overFitValue(comment) ? 1 : 2
      Object.keys(comment.member_size).forEach(sizeKey => {
        if (
          (sizeKey != 'member_overall_fit' &&
            sizeKey != 'member_brasize' &&
            sizeKey != 'member_bra_size' &&
            comment.member_size[sizeKey]) ||
          ((sizeKey == 'member_brasize' || sizeKey == 'member_bra_size') &&
            this.showBraSize &&
            comment.member_size[sizeKey])
        ) {
          if (miniMemberSize.length < arrLength) {
            miniMemberSize.push({
              sizeKey: sizeKey,
              sizeValue: comment.member_size[sizeKey]
            })
          }
        }
      })
      return miniMemberSize
    },
    miniSizeInfo(comment) {
      let miniSizeInfo = []
      let arrLength = this.overFitValue(comment) ? 1 : 2
      if (comment.member_cat_size?.size_info?.length) {
        for (let i = 0; i < comment.member_cat_size.size_info.length; i++) {
          if (comment.member_cat_size.size_info[i]) {
            miniSizeInfo.push(comment.member_cat_size.size_info[i])
            if (miniSizeInfo.length === arrLength) return miniSizeInfo
          }
        }
        
        return miniSizeInfo
      } else {
        return []
      }
    },
    getAnalysisData({ commentId = '', index, outStoreReview, sameFlag, language_flag, comment_image, content_tag, member_size, member_cat_size }) {
      const { commentTranslateConfig, curPage, showPointProgram, innerSizeIsFold } = this
      const commentIdStr = String(commentId || '')
      const isReport = commentIdStr.indexOf('rp') > -1
      const reviewTypeStr = outStoreReview ? 1 : isReport ? 2 : 0
      const contentTag = (() => {
        if (!Array.isArray(content_tag)) {
          return []
        }
        return content_tag.filter(tag => tag && tag.name && tag.content)
      })()

      const isSizeInfoExpand = (() => {
        if(outStoreReview || !innerSizeIsFold) return false
        return this.miniMemberSize({ member_size, member_cat_size }).length > 0 || this.miniSizeInfo({ member_size, member_cat_size }).length > 0
      })()
      return {
        id: commentIdStr.indexOf('rp') > -1 ? '1-6-2-22' : '1-6-2-5',
        data: {
          review_id: commentId, // 评论 ID
          position: index, // 位置序号，从 1 递增
          page: curPage - 1, // 页码序号，从 1 递增
          review_type: reviewTypeStr, //评论类型，0=站内评论，1=站外评论，2=试用报告
          review_source: sameFlag || 0, //评论来源，0=主商品评论，1=同款评论（包括保留款 + buybox最低价款）
          is_translate: commentTranslateConfig[language_flag]?.length > 0 ? 1 : 0, // 是否有单条翻译，0=无翻译按钮展示，1=有翻译按钮展示
          is_point_rating: +showPointProgram, // 是否有 Point Program，0=无Point Program展示，1=有Point Program展示
          image_num: comment_image?.length ?? 0, //评论中图片数量，0=评论无图，有图的情况下有几张图片报几张图片
          is_size_info_expand: +isSizeInfoExpand, //是否有尺码信息展开，0=没有尺码信息展开，1=有尺码信息展开
          is_guide_label: contentTag.length ? 1 : 0, //是否有 引导标签，0=没有引导标签，1=有引导标签
        }
      }
    },
    transformImg
  }
}
</script>

<style lang="less">
@pagePaddingBottom: 1.546667rem;

.goods-detail__comment-drawer {
  .review-new__filter-wrap{
    border-top:10px solid #f5f5f5;
    width: 100%;
  }
  .review-new__filter{
    position: sticky;
    z-index: 1000;
    top: 0;
  }
  .S-drawer__body {
    height: 100%;
  }
  .review-split {
    width: 100%;
    height: 0.22rem;
    background-color: #f6f6f6;
  }
}
.goods-detail__review-single-item {
  z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
  // TODO 这里会影响每个 item 层级
  // transform: translateZ(1px);
}
.review-modal {
  height: 100%;
  .mshe-z-returnTop {
    z-index: 199999; /* stylelint-disable-line declaration-property-value-blacklist */
  }
  .review-label {
    margin-top: 1.1734rem;
  }
}
.review-modal__top {
  .review-label {
    margin-top: 0;
  }
  .view-review__list.with-padding-top {
    padding-top: 0;
  }
}
.goods-review {
  &__title {
    /* rw:begin */
    font-family: 'Adieu';
  }
  &__valuable-reviews{
    margin: 0.21rem 0.32rem 0;
    padding: 0.16rem 0.21333rem;
    background: #F5FCFB;
    display: flex;
    align-items: flex-start;
  }
  &__valuable-tips{
    .font-dpr(22px);
    color: #198055;
    margin-left: 0.10667rem;
  }
  &__rate-wrap {
    position: relative;
    background: #fff;
    // padding: 0.21rem 0.32rem 0.053333rem;

    &.more-padding-bottom {
      margin-bottom: 0.32rem;
    }
  }
  &__filter-wrap {
    border-bottom: 1px solid @sui_color_gray_weak1;

    &.flex-box {
      display: flex;
      justify-content: space-between;
    }
  }
  &__rate-nomg {
    margin: 0.21rem 0.32rem 0.053333rem;
  }
}
.list-filter-layer {
  position: fixed;
  top: 0;
  right: 0 /*rtl:ignore*/;
  bottom: 0;
  left: 0 /*rtl:ignore*/;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 199; /* stylelint-disable-line declaration-property-value-blacklist */
  transform: translate3d(0, 0, 199px);
}
.translate-drawer {
  /* stylelint-disable-next-line selector-class-pattern,selector-class-pattern */
  &.c-drawer-bot-panel {
    max-height: 10rem;
  }
  &__header {
    height: 1.06rem;
    color: #999999;
    text-align: center;
    font-size: 14px;
    line-height: 1.06rem;
    border-bottom: 1px solid #e5e5e5;
  }
  &__list {
    ul {
      max-height: 7.2rem;
      border-bottom: 0.22rem solid #f7f4f8;
      overflow-y: auto;
    }
    li {
      height: 1.06rem;
      line-height: 1.06rem;
      list-style: none;
      padding: 0 0.32rem;
      font-size: 14px;
      .flexbox();
      justify-content: space-between;
      &.active {
        font-weight: bold;
      }
      position: relative;
    }
    .icon-normal-checked {
      position: absolute;
      right: 0.32rem;
    }
    button {
      border: none;
      background: transparent;
      margin: 0 auto;
    }
  }
  &__cancel {
    height: 1.28rem;
    line-height: 1.28rem;
    text-align: center;
    font-size: 14px;
    width: 100%;
    border: none;
    background: transparent;
  }
}
.translate-drawer-mask {
  position: fixed;
  top: 0;
  right: 0 /*rtl:ignore*/;
  bottom: 0;
  left: 0 /*rtl:ignore*/;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 199; /* stylelint-disable-line declaration-property-value-blacklist */
  transform: translate3d(0, 0, 199px);
}
.comment-filterslected() {
  position: absolute;
  top: 0;
  right: 0;
  .margin-r(-1px);
  content: '';
  width: 0.4rem;
  height: 0.4rem;
  display: inline-block;
  background: url('/pwa_dist/images/filter-selected-457931bc64.png');
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(0deg) /*rtl:rotate(-90deg)*/;
}
.batch-translate {
  background: linear-gradient(180deg, #f6f6f6 0%, #f6f6f6 100%);
  margin: 1rem 0.32rem 0.32rem;
  color: #666;
  padding: 0.32rem;
  &.label-top{
    margin-top: 0;
  }
  &.filter-top{
    margin-top: 0;
    padding-top: .32rem;
  }
  &.notop {
    margin-top: 0;
  }
  &__text {
    display: inline-block;
  }
  &__text-blue {
    color: #1860a7;
  }
  &__handler {
    float: right;
    width: 1rem;
    height: 0.54rem;
    border-radius: 0.28rem;
    padding: 0.05rem;
    div {
      width: 0.45rem;
      height: 0.45rem;
      display: inline-block;
      border-radius: 50%;
    }
  }
  &__handler-open {
    background: #666666;
  }
  &__handler-close {
    background: #e5e5e5;
  }
  &__active {
    background: #fff;
  }
}
.list-filter {
  position: fixed;
  top: 0;
  bottom: 0;
  .right(0);
  height: 100%;
  width: 8.84rem;
  background: rgba(246, 246, 246, 1);
  .zindex-translatez(
    @zindex-transform1,
    translate3d(0, 0, @tranlatez-transform1)
  );
  .list-mask {
    position: fixed;
    width: 10rem;
    left: 0 /*rtl:ignore*/;
    right: 0 /*rtl:ignore*/;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  &__header {
    text-align: center;
    width: 100%;
    padding: 0.28rem 0.32rem;
    border-bottom: 1px solid #e5e5e5;
    .font-dpr(34px);
    background: #fff;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    margin: 0 auto;
    .zindex-translatez(
      @zindex-trans-mask1,
      translate3d(0, 0, @tranlatez-trans-mask1)
    );
    > h3 {
      text-transform: inherit;
      margin: 0 auto;
      font-weight: bold;
    }
    > i {
      position: absolute;
      .left(0.32rem);
      /*sh:begin*/
      .font-dpr(48px);
      /*rw:begin*/
      font-size: 16px;
    }
  }
  &__contain {
    padding-top: 1.24rem;
    padding-bottom: 1.56rem;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    background: #fff;
  }
  &__inner {
    padding: 0 0.32rem 0;
    background: #fff;
    .filter-top {
      .flexbox();
      .space-between();
      padding: 0.28rem 0;
      em {
        font-weight: bold;
        .font-dpr(28px);
      }
    }
  }
  &__con {
    position: relative;
    &.over-hide {
      max-height: 2.08rem;
      overflow: hidden;
    }
  }
  &__con-color {
    display: inline-block;
    padding: 0 0.21rem;
    line-height: 0.78rem;
    border: 1px solid #e5e5e5;
    margin-bottom: 0.32rem;
    .margin-r(0.32rem);
    color: #666;
    img {
      width: 0.45rem;
      height: 0.45rem;
      .margin-r(0.08rem);
      vertical-align: middle;
    }
    span {
      .font-dpr(24px);
      vertical-align: middle;
    }
  }
  &__con-color.active {
    position: relative;
    color: #222 /*rw:#ff696e*/;
    border: 1px solid #222 /*rw:1px solid #ff696e*/;
    &::before {
      .comment-filterslected;
      /*rw:begin*/
      display: none;
    }
  }
  &__con-item {
    height: 1.04rem;
    padding-bottom: 0.32rem;
    .padding-r(0.32rem);
    .fl();
    > span {
      color: #666;
      border: 1px solid #e5e5e5;
      line-height: 0.78rem;
      min-width: 1.56rem;
      text-align: center;
      display: inline-block;
      &.active {
        position: relative;
        color: #222 /*rw:#ff696e*/;
        border: 1px solid #222 /*rw:1px solid #ff696e*/;
        &:before {
          .comment-filterslected;
          /*rw:begin*/
          display: none;
        }
      }
    }
    .filter-rating-span {
      color: @sui_color_main;
      &.active {
        color: @sui_color_main;
      }
      .sui_icon_star_5_12px_2 {
        /* stylelint-disable-line */
        color: @sui_color_honor;
      }
    }
    .filter-pic-span {
      padding: 0 0.21rem;
    }
    .loading {
      pointer-events: none;
    }
  }
  &__bottom {
    height: 1.6rem;
    .flexbox();
    .space-between();
    .align-center();
    background: #fff;
    padding: 0 0.32rem;
    box-sizing: border-box;
    &.list-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      margin: 0 auto;
      .zindex-translatez(
        @zindex-trans-mask1,
        translate3d(0, 0, @tranlatez-trans-mask1)
      );
    }
    .filter-bot-left {
      .font-dpr(20px);
      > span {
        color: #222;
        font-weight: bold;
        .font-dpr(28px);
        display: block;
      }
    }
    .filter-bot-right {
      .flexbox();
      .align-center();
      > i {
        .font-dpr(48px);
        padding: 0.4rem;
        .padding-l(0.64rem);
      }
      > button {
        .margin-l(0.53rem);
        height: 0.93333rem;
        line-height: 0.93333rem;
        .font-dpr(32px);
        font-weight: normal;
        text-transform: capitalize;
      }
    }
  }
}
.view-review.view-review-mt {
  /* stylelint-disable-line */
  margin-top: 1.15rem;
  position: relative;
}
.view-review__list.with-padding-top {
  padding-top: 1.1734rem;
}
.view-review__list.view-review__list-low {
  padding-top: 0.53rem;
}
/* stylelint-disable */
.mshe-trans-contain-footer {
  margin-top: 1.36rem;
  padding-bottom: 1.36rem;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 10rem;
  .zindex-translatez(@zindex-transform2, translate3d(0, 0, 0));
}
.mshe-header-fixed {
  position: absolute;
  top: 0;
  width: 10rem;
  margin: 0 auto;
  z-index: @zindex-transform3;
}
.bag-btn-wrap {
  position: absolute;
  font-size: 0.45rem;
  right: 0.32rem;
  text-decoration: none;
  i {
    color: #222222;
    font-size: 0.64rem;
  }
  > em {
    position: absolute;
    top: 0;
    .right(-0.2rem);
    min-width: 0.4rem;
    height: 0.4rem;
    padding: 0.1rem;
    box-sizing: border-box;
    .font-dpr(20px);
    display: block;
    border-radius: 0.4rem;
    text-align: center;
    line-height: 0.2rem;
    background: #ff411c;
    color: #fff;
  }
}
.comment-add-bag {
  position: fixed;
  z-index: @zindex-video-layer;
}

.common-reviews__fuzzy-tips {
  padding-top: 0.64rem;
  padding-bottom: 0.64rem;
  background-color: #f6f6f6;

  &.with-padding-bottom {
    padding-bottom: 0.64rem + @pagePaddingBottom;
  }

  & > p {
    color: @sui_color_gray_light1;
    text-align: center;
    font-size: 0.32rem;
    font-weight: 400;
  }
}
.show-local__container {
  [class*='sui_icon_star'] {
    /* rw:begin */
    font-size: 0.32rem !important;
  }
}

.view-review__onlyStoreTipInside {
  width: 100%;
  padding: 0.32rem 0.32rem 0.106667rem;

  .view-review__only-store-tipContent {
    .font-dpr(28px);
    font-weight: 400;
    line-height: 0.64rem;
    color: #222;

    .flexbox();

    span,
    i {
      display: inline-block;
    }
    span {
      font-weight: 600;
    }
    i {
      padding: 0 0.16rem;
      color: #959595;
      .font-dpr(28px);
    }
  }
}

.review-modal__noMoreCommentTip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 1.44rem;
  padding: 0 0.32rem;
  background-color: #f6f6f6;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 0.013333rem;
    background-color: @sui_color_gray_weak1;
    flex-grow: 1;
  }

  h3 {
    font-size: 12px;
    font-weight: 400;
    color: @sui_color_gray_dark2;
    padding: 0 0.266667rem;
  }
}

.j-expose__page_reviews__item.with-padding-bottom {
  padding-bottom: @pagePaddingBottom;
  &:first-child{
    padding-bottom: 0;
  }
}
/* stylelint-enable */

.fold-last-comment .review-item {
  height: 2.6087rem;
  overflow: hidden;
}
.comment-reviews__tologin {
  @height: 0.82126rem;
  position: relative;
  width: 100%;
  top: -@height;
  z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
  &.with-padding-bottom {
    top: -(@height + @pagePaddingBottom);
  }
  &_text {
    width: 100%;
    height: @height;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666;

    .icon {
      margin-left: 0.08rem;
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -@height;
    height: 0.966rem;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  }
}
.modal-diglog {
  .S-dialog__normal {
    width: 5.87rem;
    min-height: 1.11rem;
    padding-bottom: 0.13rem;
  }
  .S-dialog__top {
    display: none;
  }
  .S-dialog__body {
    padding: 0;
  }
  &__content {
    padding: 0.6rem 0.4rem 0.47rem;
    box-sizing: border-box;
    font-size: 12px;
    text-align: center;
    line-height: 1.5;
  }
  &__content [class*='iconfont'] {
    display: block;
    font-size: 45px;
    margin: -0.4rem auto 0.2rem;
  }
}

/** c-uptt 组件内部实现  */
body.no-fullscreen {
  .newstyle-uptt {
    margin-bottom: constant(safe-area-inset-bottom);
    margin-bottom: env(safe-area-inset-bottom);
  }
}
body.ipx-fullscreen {
  .newstyle-uptt {
    margin-bottom: 34px;
  }
}
.newstyle-uptt {
  box-sizing: content-box;
  display: none;
  position: fixed;
  .right(0.51rem);
  bottom: var(--scoll-to-top-bottom);
  width: 0.96rem;
  height: 0.96rem;
  line-height: 0.96rem;
  border-radius: 100%;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  color: #767676;
  animation: fadeUp 0.3s;
  text-align: center;
  -webkit-appearance: none !important; /* stylelint-disable-line declaration-no-important */
  [class*='iconfont'] {
    .font-dpr(40px);
  }
}
.S-popup-parent__hidden-multi {
  overflow: hidden;
}
.S-button {
  &.with-loading {
    position: relative;
    color: transparent;
    .S-loading {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
    }
    .S-loading__common-inner {
      margin: 0 auto;
      width: 0.427rem;
      height: 0.427rem;
    }
    .S-loading__circular-path-under {
      stroke: rgba(255, 255, 255, 0.3);
    }
    .S-loading__circular-path {
      stroke: rgba(255, 255, 255, 1);
    }
  }
}
</style>
