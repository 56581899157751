<template>
  <div class="show-local__container">
    <div class="left">
      <template v-if="!isDisable">
        <span>{{ language.SHEIN_KEY_PWA_20366 }}</span>
        <span class="rate-num">{{ localRating || '' }}</span>
        <span
          class="star-box"
          v-html="localRatingStarHTML"
        ></span>
      </template>
      <template v-else>
        <span>{{ language.SHEIN_KEY_PWA_20367 }}</span>
      </template>
    </div>
    <div
      :class="{
        right: true,
        loading: loading
      }"
    >
      <s-switch
        v-model="localStatus"
        :class="{ 'c-switch-ar': GB_cssRight }"
        :disable="isDisable"
        @change="changeHandle"
        @click.native="clickLocalHandle()"
      />
    </div>
  </div>
</template>

<script>
import SSwitch from 'public/src/pages/components/switch.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { template } from '@shein/common-function'
import { mapState, mapMutations, mapGetters } from 'vuex'
/* globals GB_SHEIN_counting_star_suiicon */

export default {
  name: 'ShowLocalSwitch',
  components: { SSwitch },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localStatus: false
    }
  },
  computed: {
    ...mapState('newProductDetail/CommentPopup', ['onlyLocalStatus', 'commentInfoTotal']),
    ...mapGetters('newProductDetail', ['commentOverView']),
    ...mapGetters('newProductDetail/common', ['language', 'pageCommonInfo']),
    ...mapGetters('newProductDetail/Reviews', ['commentOverViewLocal']),
    ...mapGetters('newProductDetail/Middle', ['allCommentSum']),
    isDisable() {
      return !this.commentOverViewLocal?.comment_num
    },
    localRating() {
      return this.commentOverViewLocal?.comment_rank_average || null
    },
    localcommentNum() {
      return this.commentOverViewLocal?.comment_num || null
    },
    globalRating() {
      return this.commentOverView?.comment_rank_average || null
    },
    globalReviewCount() {
      return this.commentInfoTotal || null
    },
    // 本地评分星级html
    localRatingStarHTML() {
      const { localRating } = this
      const { IS_RW } = this.pageCommonInfo
      if (!localRating) {
        return ''
      }
      return GB_SHEIN_counting_star_suiicon(localRating, IS_RW ? 20 : 12, IS_RW ? '' : 2)
    }
  },
  watch: {
    onlyLocalStatus: {
      handler(val) {
        this.localStatus = val
      },
      immediate: true
    }
  },
  mounted() {
    this.exposeLocalReviews()
  },
  methods: {
    ...mapMutations('newProductDetail/CommentPopup', ['updateOnlyLocalStatus']),
    changeHandle(val) {
      this.updateOnlyLocalStatus(val)
      this.$emit('change', val)
    },
    exposeLocalReviews() {
      const { localRating, globalRating, globalReviewCount, localcommentNum, isDisable } = this
      daEventCenter.triggerNotice({
        daId: '1-6-2-42',
        extraData: {
          global_rating: globalRating,
          global_review_count: globalReviewCount,
          local_rating: localRating,
          local_review_count: localcommentNum,
          status: isDisable ? 'unable' : this.onlyLocalStatus ? 'on' : 'off'
        }
      })
    },
    clickLocalHandle() {
      const { isDisable } = this
      daEventCenter.triggerNotice({
        daId: '1-6-2-43',
        extraData: {
          status: isDisable ? 'unable' : this.onlyLocalStatus ? 'on' : 'off'
        }
      })
    },
    getTop() {
      let headerHeight = document.getElementsByClassName('mshe-header-fixed')[0].clientHeight
      return (
        document.getElementsByClassName('show-local__container')?.[0]?.getBoundingClientRect().top -
        headerHeight
      )
    },
    template
  }
}
</script>

<style lang="less" scoped>
.show-local__container {
  width: 100%;
  height: 0.9375rem;
  padding: 0 0.32rem;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 0.013rem solid #f6f6f6;

  .left {
    display: flex;
    align-items: center;
    font-size: 0.32rem;
    font-weight: 400;
    color: @sui_color_gray_dark1;

    .rate-num {
      margin: 0 0.106667rem;
      font-weight: 700;
    }

    .star-box {
      display: inline-block;
      color: @sui_color_honor;
    }
  }
  /* stylelint-disable selector-max-specificity */
  .right {
    .c-switch {
      border: 1px solid #cccccc;
      border-radius: 1.33rem;
      width: 0.96rem;
      height: 0.56rem;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      background: #e5e5e5;
      user-select: none;
      box-sizing: border-box;
      &:after {
        position: absolute;
        width: 0.5067rem;
        height: 0.5067rem;
        background: #fff;
        border-radius: 0.4267rem;
        top: 0;
        left: 0;
        content: ' ';
        transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      }
      &.c-switch-checked {
        /* stylelint-disable-line */
        background: #666;
        & when(@IS_RW) {
          background: #ff696e;
        }
        &:after {
          /* stylelint-disable-line */
          left: 100% /*rtl:ignore*/;
          margin-left: -0.5rem /*rtl:ignore*/;
        }
      }
    }
    .c-switch-ar {
      .c-switch;
      &:after {
        right: 0; /*rtl:ignore*/
      }
      &.c-switch-checked {
        /* stylelint-disable-line */
        &:after {
          /* stylelint-disable-line */
          right: 100% /*rtl:ignore*/;
          margin-right: -0.5rem /*rtl:ignore*/;
        }
      }
    }
  }
  .loading {
    pointer-events: none;
  }
}
.show-local__container-new{
  border-bottom: 0;
  background: #f6f6f6;
  height: 0.82667rem;
  .left{
    color: #000;
    font-weight: 510;
  }
}
</style>
