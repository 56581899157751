<template>
  <div
    class="picture-rating-filter"
  >
    <!-- 图片选项 -->
    <div
      v-for="(picture, index) in picOptions"
      :key="`pic ${index}`"
      class="picture-rating-filter_option"
      :class="{ active: selectedPicture == picture.value }"
    >
      <span
        @click="onClickPicOption(picture)"
        v-html="picture.name"
      ></span>
    </div>

    <!-- 评分选项 -->
    <div
      v-for="(rating, index) in ratingOptions"
      :key="index"
      class="picture-rating-filter_option"
      :class="{ active: selectedRating === rating }"
    >
      <span
        class="picture-rating-filter_rating-option"
        @click="onClickRatingOption(rating)"
      >
        {{ rating }}
        <i class="suiiconfont sui_icon_star_5_12px_2"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PictureRatingFilterRomwe',
  props: {
    picOptions: {
      type: Array,
      default: () => ([])
    },
    ratingOptions: {
      type: Array,
      default: () => ([])
    },
    selectedPicture: {
      type: Number,
      default: -1
    },
    selectedRating: {
      type: String,
      default: ''
    },
  },
  methods: {
    onClickPicOption(picture) {
      this.$emit('selectPicture', picture)
    },
    onClickRatingOption(rating) {
      this.$emit('selectRating', rating)
    }
  }
}
</script>

<style lang="less">
.picture-rating-filter {
  display: flex;
  flex-wrap: wrap;
  padding: .266667rem .32rem;

  &_option {
    display: block;
    padding: 0 .32rem;
    line-height: 0.78rem;
    border-radius: .44rem;
    border: 1px solid @sui_color_gray_weak1;
    margin-bottom: 0.32rem;
    margin-right: .32rem;
    text-align: center;
    .margin-r(0.32rem);
    color: #959595;
    span {
      .font-dpr(24px);
      vertical-align: middle;
    }

    &.active {
      color: @sui_color_black;
      border: 1px solid @sui_color_black;
    }
  }

  .picture-rating-filter_rating-option {
    .sui_icon_star_5_12px_2 {
      color: @sui_color_honor;
    }
  }
}
</style>
