<template>
  <div class="review-sort__recommed">
    <div 
      v-for="(item,index) in sortListMap" 
      :key="item.key"
      class="review-sort__recommed-item"
      :class="{active: item.active}"
      @click="handleClick(item,index)"
    >
      {{ item.key }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    language: {
      type: Object,
      default: (() => {})
    },
  },
  data() {
    return {
      sortListMap: [{
        key: this.language.SHEIN_KEY_PWA_30602 || 'Recommended', 
        keyEn: 'Default',
        biName: 'click_sort_default',
        sort: 'default',
        type: 'Recommend',
        value: 0,
        active: true
      }, {
        key: this.language.SHEIN_KEY_PWA_30603 || 'Most recent	',
        keyEn: 'Most Recent to Oldest',
        biName: 'click_sort_recent_to_old',
        sort: 'time_desc',
        type: 'Most recent',
        value: 3,
        active: false
      }]
    }
  },
  methods: {
    handleClick(item, index) {
      const isActive = this.sortListMap[index].active
      if(isActive) return
      this.sortListMap = this.sortListMap.map((sortItem) => {
        sortItem.active = sortItem.key === item.key
        return sortItem
      })
      this.$emit('changeSort', item)
    }
  }
}
</script>

<style lang="less">
.review-sort {
  &__recommed{
    padding:0.26666rem 0.32rem;
    display: flex;
    justify-content: flex-start;
  }
  &__recommed-item {
    font-size: 0.32rem;
    font-style: normal;
    line-height: normal;
    color: var(---sui_color_gray_dark2, #666);
    &:not(:last-child):after {
      content: '|';
      margin-right: 0.16rem; 
      margin-left: 0.16rem;
      color: #BABABA;
      
    }
  }
  &__recommed-item.active {
    color: #222;
    font-weight: 700;
  }
}
</style>
