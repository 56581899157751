<template>
  <li
    class="goods-review__sort-item"
    :class="{ active: target == sort }"
    @click="handleClick"
  >
    <span v-html="name"></span>
    <i
      v-if="target == sort"
      class="iconfont icon-normal-checked"
    ></i>
  </li>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    val: {
      type: [Number, String],
      default: ''
    },
    sort: {
      type: String,
      default: ''
    },
    target: {
      type: [Number, String],
      default: ''
    }
  },
  methods: {
    handleClick() {
      this.$emit('click', this.val)
    }
  }
}
</script>

<style lang="less">
.goods-review {
  &__sort-item {
    color: #666;
    [class*='iconfont'] {
      color: #222 /*rw:#ff696e*/;
      .font-dpr(32px);
    }
    &.active {
      color: #222;
      span {
        font-weight: bold;
      }
    }
  }
}
</style>
