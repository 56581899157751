var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.shouldShow)?_c('div',{class:{
    'goods-review__filter-panel': true,
    'limit-width': _vm.isRomwe
  }},[(!_vm.hiddenTitle)?_c('div',{staticClass:"panel-box",on:{"click":_vm.onTitleClick}},[_c('span',[_vm._v(_vm._s(_vm.titleText))]),_vm._v(" "),_c('i',{class:{
        suiiconfont: true,
        sui_icon_more_down_16px: true,
        reverse: _vm.panelShow
      }})]):_vm._e(),_vm._v(" "),_c('S-slide',{ref:"slidePanel",attrs:{"visible":_vm.panelShow,"modal":true},on:{"update:visible":function($event){_vm.panelShow=$event},"closed":_vm.handleClose}},[_c('div',{staticClass:"goods-review__dropdown filter-panel",class:{ 'romwe-style': _vm.isRomwe }},[_c('div',{staticClass:"scroll-box",style:({ maxHeight: _vm.selectAreaMaxHeight })},[(_vm.hasColorOption)?[_c('p',[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_16265))]),_vm._v(" "),_c('ul',_vm._l((_vm.colors),function(color,index){return _c('li',{key:index,class:{
                option: true,
                'color-option': true,
                active: color.goods_id === _vm.selectedColor,
                loading: _vm.loading
              },on:{"click":() => _vm.onSelectColor(color)}},[_c('img',{attrs:{"src":_vm.transformImg({ img: color.goods_color_image })}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(color.color_text))])])}),0)]:_vm._e(),_vm._v(" "),(_vm.hasSizeOption)?[_c('p',[_vm._v(_vm._s(_vm.sizeInfo.attr_name))]),_vm._v(" "),_c('ul',_vm._l((_vm.sizeInfo.attr_values),function(size,index){return _c('li',{key:index,class:{
                option: true,
                'size-option': true,
                active: +size.attr_value_id === +_vm.selectedSize,
                loading: _vm.loading
              },on:{"click":() => _vm.onSelectSize(size, index)}},[_c('span',[_vm._v(_vm._s(size.attr_value)+_vm._s(_vm.getSizeCorrespond(size.attr_value_en))+" ")])])}),0)]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"filter-panel_bottom-bar"},[_c('div',{staticClass:"review-count-box"}),_vm._v(" "),_c('div',{staticClass:"operate-box"},[_c('i',{staticClass:"iconfont icon-delete",on:{"click":function($event){$event.stopPropagation();return _vm.resetFilter.apply(null, arguments)}}}),_vm._v(" "),_c('s-button',{class:{ 'mshe-btn-black': true, 'with-loading': _vm.loading },attrs:{"type":['primary']},on:{"click":_vm.onClosePanel}},[_vm._v("\n            "+_vm._s(_vm.language.SHEIN_KEY_PWA_15042)+"\n            "),_c('s-loading',{attrs:{"show":_vm.loading}})],1)],1)])])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }