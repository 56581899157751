<template>
  <div
    class="sort-romwe__sortfilt"
    :class="showSortList ? 'active' : ''"
  >
    <div
      class="sort-romwe__sortfilt-item"
      @click="toggleSort"
    >
      <em>{{ language.SHEIN_KEY_PWA_15500 }}</em>
      <i
        :class="{
          suiiconfont: true,
          sui_icon_more_down_16px: true,
          reverse: showSortList
        }"
      ></i>
    </div>
    <S-slide
      ref="s-slide"
      :visible.sync="showSortList"
      :modal="true"
    >
      <div class="sort-romwe__dropdown">
        <slot name="sortItems"></slot>
      </div>
    </S-slide>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
export default {
  name: 'SortRomweV2',
  props: {},
  data() {
    return {
      showSortListProxy: false
    }
  },
  computed: {
    ...mapState('newProductDetail/CommentPopup', ['showSortList']),
    ...mapGetters('newProductDetail/common', ['language'])
  },
  watch: {
    showSortListProxy(newVal) {
      this.updateShowSortList(newVal)
    },
    showSortList(newVal) {
      this.showSortListProxy = newVal
      if (newVal) {
        $('.j-review-scroll-ctn').css('overflow', 'hidden')
        $('body').css('overflow', 'hidden')
      } else {
        $('.j-review-scroll-ctn').css('overflow', '')
        $('body').css('overflow', '')
      }
      this.$nextTick(() => {
        this.$refs['s-slide'].calHeight()
      })
    }
  },
  methods: {
    ...mapMutations('newProductDetail/CommentPopup', ['updateShowSortList']),
    toggleSort: function () {
      this.updateShowSortList(!this.showSortList)
      this.$parent.current = this
      this.$emit('toggle')
      if (typeof sa !== 'undefined' && this.showSortList) {
        sa('send', { activity_name: 'click_sort' })
      }
    }
  }
}
</script>

<style lang="less">
.sort-romwe {
  &__sortfilt {
    white-space: nowrap;
    max-width: 35%;
    height: 1.1734rem;
    font-size: 0.374rem;
    background: #fff;
    [class*='iconfont'] {
      // .margin-l(0.2rem);
      color: #999;
    }
    /* stylelint-disable selector-class-pattern */
    .icon-Product_list_down {
      /* stylelint-disable-line */
      display: inline-block;
      .font-dpr(32px);
      font-weight: normal;
    }
  }
  &__sortfilt-item {
    padding: 0.213333rem;
    padding-right: calc(0.27rem + 0.426667rem + 0.106667rem);
    background: #fff;
    display: block;
    width: 100%;
    color: @sui_color_gray_dark1;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::before {
      content: '';
      height: 0.48rem;
      width: 0;
      border-left: 1px solid @sui_color_gray_weak1;
      position: relative;
      left: -0.27rem;
    }

    .sui_icon_more_down_16px {
      display: inline-block;
      position: absolute;
      right: 0.27rem;
      top: 0.23rem;
    }
    .sui_icon_more_down_16px.reverse {
      transform: rotate(180deg);
      transition: transform 0.3s;
    }
  }
  &__dropdown {
    background: #fff;

    .goods-review__sort-item:first-child {
      border-top: none;
    }
  }
}
</style>
