<template>
  <div
    ref="scroll"
    class="new-review__filter"
  >
    <div
      v-for="item in sortListMap"
      :key="item.key"
      class="new-review__filter-item"
      :class="{ active: active[item.activeKey]|| item.arrowActive }"
      @click="item.click"
    >
      {{ item.key }}
      <i
        :class="{
          suiiconfont: true,
          sui_icon_more_down_16px: item.hasArrow,
          sui_icon_photos_16px_2: item.hasPicIcon,
          reverse: item.arrowActive
        }"
      ></i>
    </div>
  </div>
</template>

<script>

import { daEventCenter } from 'public/src/services/eventCenter/index'
import { mapState, mapMutations } from 'vuex'
export default {
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    showReportList: {
      type: Boolean,
      default: false
    },
    colors: {
      type: Array,
      default: () => []
    },
    sizeInfo: {
      type: Object,
      default: () => ({})
    },
    active: {
      type: Object,
      default: () => {}
    },
    commentRank: {
      type: String,
      default: ''
    },
    colorSizeNumber: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      scrollTimer: null
    }
  },
  computed: {
    ...mapState('newProductDetail/CommentPopup', [
      'showRatingList',
      'showColorSizeList',
      'withPicture',
      'comment_rank',
      'onlyShowReport'
    ]),
    // 是否存在颜色选项
    hasColorOption() {
      const { colors } = this
      return colors && colors.length > 1
    },
    // 是否存在尺码选项
    hasSizeOption() {
      const { sizeInfo = {} } = this
      const { attr_values } = sizeInfo
      return attr_values && attr_values.length
    },
    shouldShow() {
      const { hasColorOption, hasSizeOption } = this
      return hasColorOption || hasSizeOption
    },
    // size/color文本
    sortListMap() {
      const commentRankNum = this.commentRank
        ? `(${this.commentRank?.split(',')?.length})`
        : ''
      const list = [
        {
          key: this.language.SHEIN_KEY_PWA_15094,
          activeKey: 'picture',
          hasArrow: false,
          hasPicIcon: true,
          arrowActive: false,
          click: () => {
            this.$emit('handlePicture')
            this.updateShowRatingList(false)
            this.updateShowColorSizeList(false)
          }
        },

        {
          key: `${this.language.SHEIN_KEY_PWA_15332}${commentRankNum}`,
          activeKey: 'rating',
          value: 3,
          active: false,
          hasArrow: true,
          arrowActive: this.showRatingList,
          click: () => {
            this.$emit('chooseStarFilterPanel')
            this.updateShowColorSizeList(false)
            this.$nextTick(() => {
              this.updateShowRatingList(!this.showRatingList)
            })
          }
        }
      ]
      if (this.titleText) {
        list.unshift({
          key: this.titleText,
          activeKey: 'colorSize',
          active: false,
          hasArrow: true,
          arrowActive: this.showColorSizeList,
          click: () => {
            this.$emit('chooseColorSizeFilter')
            this.updateShowRatingList(false)
            this.$nextTick(() => {
              this.updateShowColorSizeList(!this.showColorSizeList)
            })
          }
        })
      }
      if (this.showReportList) {
        list.push({
          key: this.language.SHEIN_KEY_PWA_15207,
          activeKey: 'trialReport',
          active: false,
          hasArrow: false,
          arrowActive: false,
          click: () => {
            !this.onlyShowReport && daEventCenter.triggerNotice({
              daId: '1-6-7-29',
            })
            this.$emit('chooseTrialReport')
            this.updateShowRatingList(false)
            this.updateShowColorSizeList(false)
            this.$refs.scroll.scrollLeft =  this.$refs.scroll.scrollWidth - this.$refs.scroll.clientWidth
          }
        })
      }
      return list
    },
    titleText() {
      if(!this.shouldShow){
        return ''
      }
      const { colorSizeNumber, language } = this
      const colorSize = colorSizeNumber > 0 ? `(${colorSizeNumber})` : ''
      const colorText = this.hasColorOption ? language.SHEIN_KEY_PWA_16265 : ''
      const hasLine = this.hasColorOption ? '/' : ''
      const sizeText = this.sizeInfo.attr_name ? `${hasLine}${this.sizeInfo.attr_name}` : ''
      return `${colorText}${sizeText}${colorSize}`
    }
  },

  mounted() {
    this.$refs.scroll.addEventListener('scroll', this.onScroll)
  },
  methods: {
    ...mapMutations('newProductDetail/CommentPopup', [
      'updateShowRatingList',
      'updateShowColorSizeList'
    ]),
    onScroll(){
      clearTimeout(this.scrollTimer)
      this.scrollTimer = setTimeout(() => {
        daEventCenter.triggerNotice({
          daId: '1-6-2-78',
        })
      }, 300)
    },
    // 获取当前选中的尺码
    // ! 因上游数据结构设计缺陷，暂时无法用computed属性代替
    getSelectedSize() {
      const { sizeInfo = {} } = this
      const { attr_values = [] } = sizeInfo
      const selectedSize = attr_values.find(
        size => +size.attr_value_id === +this.selectedSize
      )
      return selectedSize || null
    },
    // 获取当前选中尺码的字符串表达
    // ! 因上游数据结构设计缺陷，暂时无法用computed属性代替
    getSelectedSizeText() {
      const { getSelectedSize, sizeCorrespondMap } = this
      const selectedSize = getSelectedSize()
      if (!selectedSize) {
        return ''
      }
      const sizeText = selectedSize.attr_value_en
      if (sizeCorrespondMap) {
        const correspond = sizeCorrespondMap[sizeText]
        if (correspond) {
          return `${sizeText}(${correspond})`
        }
      }
      return sizeText
    }
  }
}
</script>

<style lang="less">
.new-review {
  &__filter {
    padding: 0.32rem 0.32rem;
    background: var(---sui_color_white, #fff);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    border-bottom: 1px solid #e5e5e5;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .sui_icon_more_down_16px {
      display: inline-block;
    }
    .sui_icon_more_down_16px.reverse {
      transform: rotate(180deg);
      transition: transform 0.3s;
    }
    .suiiconfont {
      margin-left: 0.1067rem;
      color:#959595;
    }
  }
  &__filter-item {
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    display: flex;
    align-items: center;
    color: #666666;
    border: 0.5px solid #bbb;
    background: var(---white, #fff);
    padding: 0px 0.32rem;
    height: 0.7467rem;
    white-space: nowrap;
    &:not(:last-child) {
      margin-right: 0.2133rem;
    }
  }
  &__filter-item.active {
    color: #222;
    font-weight: 400;
    border: 0.5px solid #222;
  }
}
</style>
